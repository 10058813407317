import axios from "axios";
import setting from "./setting";
import { downloadFile } from "../util";

const URL_BASE = `${setting.http.base_url}`;

export default {
	async getByFilters(token, filters = { page: 1 }) {
		const { data } = await axios.post(
			`${URL_BASE}/Equipos/solicitudes/lista`,
			filters,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data;
	},
	async getDetail(token, id) {
		const { data } = await axios.get(
			`${URL_BASE}/Equipos/solicitudes/detalle/` + id,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data;
	},
	async updateDetail(token, solicitud) {
		const { data } = await axios.put(
			`${URL_BASE}/Equipos/solicitudes/editar/` + solicitud.idSolicitud,
			solicitud,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data;
	},
	async downloadList(token) {
		const { data } = await axios.post(
			`${URL_BASE}/Equipos/solicitudes/exportar`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			},
		);
		if (data.status != 200) {
			throw new Error("El pedido no fue existoso");
		}
		await downloadFile(
			`${new URL(setting.http.base_url).origin}/${data.message}`,
			data.message,
		);
		return data;
	},
};
