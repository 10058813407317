<template>
  <box-loader
    containerClass="main-content-laive container-fluid"
    :loading="loading"
    style="height: 80vh;"
  >
    <div class="w-100 pt-4" v-if="Object.keys(data).length > 0">
      <h1 class="title-detail-incidence">
        Detalle de incidencia - {{ data.codigoIncidencia }}
      </h1>
      <ul class="tags-group">
        <li>{{ data.placa }}</li>
        <li>{{ data.modelo }}</li>
        <li>{{ data.lote }}</li>
        <li>{{ data.distribuidora }}</li>
      </ul>
      <div class="client-group">
        <h2>Datos del Cliente</h2>
        <ul>
          <li>
            <h4>Asignado</h4>
            <span>{{ data.codigoCliente }}</span>
          </li>
          <li>
            <h4>Nombre</h4>
            <span>{{ data.nombreCliente }}</span>
          </li>
          <li>
            <h4>Dirección</h4>
            <span>{{ data.direccion }}</span>
          </li>
          <li>
            <h4>Ubicación</h4>
            <span>
              <a
                :href="data.ubication"
                class="th-link-pry cursor-pointer"
                target="_blank"
                rel="noopener"
              >
                <i class="fa fa-map-marker-alt mr-2" />
                Ir a Google Maps
              </a>
            </span>
          </li>
          <li>
            <h4>Pedido Mínimo</h4>
            <span>{{ data.pedidoMinimo }}</span>
          </li>
          <li>
            <h4>Segmento</h4>
            <span>{{ data.segmento }}</span>
          </li>
        </ul>
      </div>

      <TrackingIndicendia
        :data="data"
        :fetchData="fetchData"
        @change-loading="setIsLoading"
      />
    </div>
  </box-loader>
</template>

<script>
import "@/assets/sass/laive/equipos/incidencia/detail-incidence.scss";

import { useToast } from "vue-toastification";
import SelectButton from "@/components/Shared/SelectButton.vue";
import TextInput from "@/components/Shared/TextInput.vue";
import { Select } from "../../../components/selects";
import { Incidencias } from "../../../services";
import TrackingIndicendia from "./TrackingIndicendia.vue";

export default {
  components: {
    SelectButton,
    TextInput,
    Select,
    TrackingIndicendia,
  },
  data() {
    return {
      data: {},
      loading: false,
      model: {
        oficinaVentas: 0,
        fechaInicio: 0,
        fechaCierre: 0,
        dex: 0,
        codigo: "",
      },
      oficinaVentas: [{ valor: 0, texto: "Todos" }],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    setIsLoading(newValue) {
      this.loading = newValue;
    },
    async fetchData() {
      try {
        if (!this.$store.state.auth.token) return;
        const { token } = this.$store.state.auth;

        const incidenciaId = this.$route.query.incidenciaId;
        if (!token || !incidenciaId) return;

        this.loading = true;

        const detail = await Incidencias.getDetail(token, incidenciaId);

        const codigoIncidencia = String(detail.idIncidencia).padStart(6, "0");
        const ubication = `https://www.google.com/maps/search/?api=1&query=${detail.latitud}%2C${detail.longitud}`;

        this.data = {
          ...detail,
          codigoIncidencia,
          ubication,
        };
      } catch (error) {
        this.toast("Ocurrio un error", "error");
      } finally {
        this.loading = false;
      }
    },
    toast(title, type = "success") {
      const toast = useToast();
      if (type == "success") {
        toast.success(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-pry",
          showCloseButtonOnHover: true,
        });
      } else if (type == "error") {
        toast.error(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-error",
          showCloseButtonOnHover: true,
        });
      }
    },
  },
};
</script>

