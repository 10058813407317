<template>
  <header
    aria-label="breadcrumb"
    class="p-3 justify-content-between align-items-center d-none d-md-flex"
  >
    <route-bread-crumb></route-bread-crumb>
    <dashboard-navbar></dashboard-navbar>
  </header>
  <div class="p-4 th-bg-slate">
    <h2 v-if="user.rol === 1 || user.rol === 2" class="fs-4 text-dark">
      Carga de equipos
    </h2>
    <h2 v-else class="fs-4 text-dark">
      No tienes permisos para ver esta sección
    </h2>
    <section
      v-if="user.rol === 1 || user.rol === 2"
      class="card shadow-lg p-3 form_laive"
      :class="{ validateClient: loading }"
    >
      <div class="box_loader">
        <div class="loader"></div>
      </div>
      <Dropzone
        customText="Para realizar la carga selecciona el formato con los equipos agregados"
        :clearDropzone="clearFiles"
        :multiple="false"
        :showButton="false"
        :allowClickContainer="true"
        ref="dropzone"
        typeFiles=".xls,.xlsx"
      />
      <div class="d-flex justify-content-between align-item-center mt-3">
        <a
          class="th-btn th-btn-border-pry text-sm align-items-center d-flex flex-row"
          href="/plantillas/maestro-activos-plantilla.xlsx"
          download
          @click="download"
        >
          Descargar plantilla
          <i class="laive-i icon-download ml-2" />
        </a>
        <div class="d-flex flex-row">
          <button
            class="th-btn th-btn-border-pry text-sm mr-2"
            @click.stop="cleanFiles()"
          >
            Cancelar
          </button>
          <button
            class="th-btn-pry th-btn-float text-sm align-items-center d-flex flex-row"
            @click.stop="upload"
          >
            Cargar
            <i class="laive-i icon-upload-white ml-2" />
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";

import Dropzone from "../../../components/Shared/Dropzone.vue";
import RouteBreadCrumb from "../../../components/Breadcrumb/RouteBreadcrumb.vue";
import DashboardNavbar from "../../Layout/DashboardNavbar.vue";

import { Equipos } from "../../../services";

const UPLOAD_ERRORS = {
  FORMAT_FILE_ERROR: "FORMAT_FILE_ERROR",
  DATA_VALIDATION_ERROR: "DATA_VALIDATION_ERROR",
};

export default {
  components: {
    DashboardNavbar,
    RouteBreadCrumb,
    Dropzone,
  },
  data() {
    return {
      clearFiles: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    cleanFiles() {
      this.clearFiles = true;
      setTimeout(() => {
        this.clearFiles = false;
      }, 500);
    },
    toast(title, type = "success") {
      const toast = useToast();
      if (type == "success") {
        toast.success(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-pry",
          showCloseButtonOnHover: true,
        });
      } else if (type == "error") {
        toast.error(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-error",
          showCloseButtonOnHover: true,
        });
      }
    },
    download() {
      setTimeout(() => {
        this.toast("Plantilla descargada");
      }, 500);
    },
    async upload() {
      try {
        const token = this.$store.state.auth.token;
        const file = this.$refs["dropzone"].files[0];

        if (!file) {
          this.toast("Tiene que selecionar el archivo", "error");
          return;
        }

        const typeFile = file.name.split(".").pop();

        if (!["xlsx", "xls"].includes(typeFile)) {
          this.toast("Solo se admite archivos de tipo: xlx o xlsx", "error");
          return;
        }

        const formData = new FormData();
        formData.append("file", file);
        this.loading = true;

        const data = await Equipos.loadDataByFile(token, formData);
        const { errors } = data;
        if (!!errors) {
          const { key, message } = errors;
          if (key === UPLOAD_ERRORS.FORMAT_FILE_ERROR) {
            this.toast("Solo se admite archivos de tipo: xlx o xlsx", "error");
          } else {
            this.toast(message, "error");
          }
        } else {
          this.toast("Plantilla cargada");
          this.cleanFiles();
        }
      } catch (error) {
        this.toast("Hubo un error en la carga de equipos de fríos", "error");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
header :deep(nav:last-child) {
  padding: 0 !important;
}

h2 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 1.3em;
}

:deep(.dropzone) {
  padding-block: 3em;
}
</style>
