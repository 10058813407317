<template>
  <div class="row mx-0 px-3">
    <div class="col-6 px-0">
      <div class="row mx-0">
        <div class="col-3 px-1">
          <b class="font-11">Buscar</b>
          <div class="">
            <GeneralInput
              v-model="searchQuery"
              tipo="text"
              :placeholder="'Cód. o nombre'"
              :fit_content="true"
              wrapper_class="py-1 mh-35px"
              suffix_icon="fas fa-search"
              suffix_icon_class="bonificacion-light-gray-text"
            ></GeneralInput>
          </div>
        </div>
        <div class="col-3 px-1">
          <b class="font-11">Tipo Bonificación</b>
          <div class="row mx-0">
            <div class="col-12 p-0">
              <GeneralDropdown
                v-model="filtro.tipobonifacion"
                aditional_class="bg-white mh-35px"
                :options="tipos_bonificacion_options"
                :is_multiple="true"
                :collapse_tags="true"
                :puede_seleccionar_todos="true"
                :resumir_tags="true"
                :fit_content="true"
              ></GeneralDropdown>
            </div>
          </div>
        </div>
        <div class="col-3 px-1">
          <b class="font-11">Región y canal</b>
          <div class="row mx-0">
            <div class="col-12 p-0">
              <GeneralDropdown
                v-model="oficinas"
                aditional_class="bg-white mh-35px"
                :options="oficinas_options"
                :is_multiple="true"
                :collapse_tags="true"
                :puede_seleccionar_todos="true"
                :resumir_tags="true"
                :fit_content="true"
              ></GeneralDropdown>
            </div>
          </div>
        </div>
        <div class="col-3 px-1">
          <b class="font-11">Dex</b>
          <div class="row mx-0">
            <div class="col-12 p-0">
              <GeneralDropdown
                v-model="filtro.dex"
                aditional_class="bg-white mh-35px"
                :options="distribuidoras_options"
                :is_multiple="true"
                :collapse_tags="true"
                :puede_seleccionar_todos="true"
                :resumir_tags="true"
                :fit_content="true"
              ></GeneralDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 px-0">
      <div class="row mx-0">
        <div class="col-3 px-1">
          <b class="font-11">Segmento</b>
          <div class="row mx-0">
            <div class="col-12 p-0">
              <GeneralDropdown
                v-model="filtro.segmento"
                aditional_class="bg-white mh-35px"
                :options="segmentos_options"
                :is_multiple="true"
                :collapse_tags="true"
                :puede_seleccionar_todos="true"
                :resumir_tags="true"
                :fit_content="true"
              ></GeneralDropdown>
            </div>
          </div>
        </div>
        <div class="col-3 px-1">
          <b class="font-11">Mesa</b>
          <div class="row mx-0">
            <div class="col-12 p-0">
              <GeneralDropdown
                v-model="filtro.mesas"
                aditional_class="bg-white mh-35px"
                :options="mesas_options"
                :is_multiple="true"
                :collapse_tags="true"
                :puede_seleccionar_todos="true"
                :resumir_tags="true"
                :fit_content="true"
              ></GeneralDropdown>
            </div>
          </div>
        </div>
        <div class="col-3 px-1">
          <b class="font-11">Tipo vendedor</b>
          <div class="row mx-0">
            <div class="col-12 p-0">
              <GeneralDropdown
                v-model="filtro.tipovendedor"
                aditional_class="bg-white mh-35px"
                :options="tipos_vendedor_options"
                :is_multiple="true"
                :collapse_tags="true"
                :puede_seleccionar_todos="true"
                :resumir_tags="true"
                :fit_content="true"
              ></GeneralDropdown>
            </div>
          </div>
        </div>
        <div class="col-3 px-1">
          <b class="font-11">Rango de fechas</b>
          <div class="">
            <div
              class="border rounded px-1 d-flex py-1 mh-35px"
              style="height: 35px"
            >
              <label
                for=""
                class="col-form-label form-control-label py-0 w-100"
              >
                <el-date-picker
                  class="w-100 icon-hidden px-0"
                  v-model="filtro.range"
                  type="daterange"
                  unlink-panels
                  range-separator="-"
                  start-placeholder="00/00"
                  end-placeholder="00/00"
                  size="small"
                  prefix-icon="none"
                  format="MM/DD"
                >
                </el-date-picker>
              </label>
              <span
                class="d-flex align-items-center bonificacion-light-gray-text"
                ><i class="far fa-calendar"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4 mx-0 w-100 thead-light font-11 py-3">
    <div class="col-3 px-0">
      <div class="row mx-0">
        <div class="col-3 px-1 text-center"><b class="font-11">Cód. </b></div>
        <div class="col-6 px-1 text-center"><b class="font-11">Nombre</b></div>
        <div class="col-3 px-1 text-center"><b class="font-11">Tipo</b></div>
      </div>
    </div>
    <div class="col-5 px-0">
      <div class="row mx-0">
        <div class="col-6 px-1 text-center">
          <b class="font-11">Producto Promocional y unidades</b>
        </div>
        <div class="col-6 px-1 text-center">
          <b class="font-11">Bonificación y Unidades</b>
        </div>
      </div>
    </div>
    <div class="col-4 px-0">
      <div class="row mx-0">
        <div class="col-2 px-1 text-center"><b class="font-11">Dex</b></div>
        <div class="col-3 px-1 text-center">
          <b class="font-11">Clientes</b>
        </div>
        <div class="col-4 px-1 text-center">
          <b class="font-11" v-if="activas">Fecha Fin</b>
          <b class="font-11" v-else>Inicio y fin</b>
        </div>
        <div class="col-3 px-1 text-center"></div>
      </div>
    </div>
  </div>
  <ListaRow
    v-for="bonificacion in bonificaciones"
    :key="bonificacion"
    :bonificacion="bonificacion"
    :show_activas="activas"
  ></ListaRow>
</template>

<script setup>
import GeneralInput from "./GeneralInput.vue";
import GeneralDropdown from "./GeneralDropdown.vue";
import ListaRow from "./ListaRow.vue";
import { ElDatePicker } from "element-plus";

import { ref, computed, watch, onMounted } from "vue";
import constantes from "@/model/constantes.js";
import { useStore } from "vuex";
import { set } from "d3";
const store = useStore();

const { activas } = defineProps({
  activas: {
    type: Boolean,
    default: true,
  },
});

const bonificaciones_raw = computed(() =>
  activas == true
    ? store.state.bonificacion.bonificaciones_activas
    : store.state.bonificacion.bonificaciones_inactivas
);

const bonificaciones = computed(() => {
  let fuente = bonificaciones_raw.value;
  const busqueda = searchQuery.value.trim();
  return busqueda
    ? fuente.filter((bonificacion) =>
        bonificacion.nombre.toLowerCase().includes(busqueda.toLowerCase())
        || bonificacion.codigo.includes(busqueda.toLowerCase())
      )
    : fuente;
});

const searchQuery = ref("");
const filtro = ref({});
const oficinas = ref(null);
const habilitar_filtro = ref(false);

const tipos_bonificacion_options = computed(() => {
  return [
    {
      value: constantes.TIPO_PROMOCION,
      label: constantes.PROMOCION_LABEL,
    },
    {
      value: constantes.TIPO_COMBO,
      label: constantes.COMBO_LABEL,
    },
    {
      value: constantes.TIPO_ESCALA,
      label: constantes.ESCALA_LABEL,
    },
    {
      value: constantes.TIPO_REBATE,
      label: constantes.REBATE_LABEL,
    },
  ];
});

const distribuidoras_options_raw = computed(() =>store.state.bonificacionUtil.distribuidoras)

const distribuidoras_options = computed(() =>
{
  let fuente = distribuidoras_options_raw.value;
  fuente =  fuente.filter(dex => oficinas.value?.includes(dex.oficinaventa));
  return fuente.map((item) => {
    return {
      value: item.iddistribuidora,
      label: item.codigodistribuidora,
    };
  })
}

);

const oficinas_options = computed(() =>
  {
    const dexs =store.state.bonificacionUtil.distribuidoras;
    const oficinas = new Set();
    dexs.forEach(dex => {
      oficinas.add(dex.oficinaventa);
    });
    return [...oficinas].map(oficina => {
      return {
      value: oficina,
      label: oficina,
    };

    })
  }
);


const segmentos_options = computed(() =>
  store.state.bonificacionUtil.segmentos.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  })
);

const tipos_vendedor_options = computed(() =>
  store.state.bonificacionUtil.tiposVendedor.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  })
);


const mesas_options = computed(() => {
  let fuente = store.state.bonificacionUtil.mesas;
  fuente = fuente.filter((item) =>
    filtro.value?.dex?.includes(item.iddistribuidora)
  );

  return fuente.map((item) => {
    return {
      value: item.idtabla,
      label: `${item.razonsocial} - ${item.nombre}`,
    };
  });
});

onMounted(() => {
  filtro.value.tipobonifacion = [
    constantes.TIPO_PROMOCION,
    constantes.TIPO_COMBO,
    constantes.TIPO_ESCALA,
    constantes.TIPO_REBATE,
  ];
  oficinas.value = oficinas_options.value.map((item) => item.value);  
  setTimeout(() => {
    habilitar_filtro.value = true;
  }, 1000);
});

watch(
  () => filtro.value,
  (nuevo, viejo) => {
    if (habilitar_filtro.value) {
      const request = {
        fechainicio: nuevo.range ? nuevo.range[0].toISOString() : "",
        fechafin: nuevo.range ? nuevo.range[1].toISOString() : "",
        tipobonifacion: nuevo.tipobonifacion.join(", "),
        dex: nuevo.dex.join(", "),
        segmento: nuevo.segmento.join(", "),
        mesa: nuevo.mesas.join(", "),
        tipovendedor: nuevo.tipovendedor.join(", "),
      };

      if (activas == true) {
        store.dispatch("bonificacion/obtenerBonificacionesActivas", request);
      } else {
        store.dispatch("bonificacion/obtenerBonificacionesInactivas", request);
      }
    }
  },
  { deep: true }
);

watch(
  () => oficinas.value,
  (nuevoValor, valorAnterior) => {
    setTimeout(() => {
      filtro.value.dex = distribuidoras_options_raw.value.filter(dex => nuevoValor == dex.oficinaventa).map((item) => item.iddistribuidora);      
    }, 100);
  }
);

</script>

<style lang="scss" scoped>
.thead-light {
  background: #f7f9fc;
  font-size: 11px;
  font-weight: 600;
}
</style>
