import { mapActions, mapGetters } from 'vuex';
import BonificacionUtilService from '../services/BonificacionUtilService';
import axios from 'axios';



export default {
    namespaced: true,
    state: {
        distribuidoras: [],
        productos: [],
        vendedores: [],
        tiposCliente: [],
        segmentos: [],
        girosNegocio: [],
        tiposVendedor: [],
        categorias: [],
        familias: [],
        presentaciones: [],
        mesas: [],
        distribuidoras_clientes: [],
    },
    getters: {},
    mutations: {
        actualizarLista(state, payload) {
            state[payload.lista] = payload.datos;
        },
        actualizarContadorCliente(state, data) {
            state.distribuidoras_clientes = data
        },
    },
    methods: {},
    actions: {
        async cargarLista({ state, commit }, lista) {
            let data = await BonificacionUtilService.cargarLista(axios, lista);
            if(lista =='mesas'){
                data = data.result;
            }
            commit("actualizarLista", { datos: data, lista });
        },
        async contarCientes({ state, commit }, payload) {
            const data = await BonificacionUtilService.contarCientes(axios, payload);
            commit("actualizarContadorCliente", data);
        },
        async obtenerCostos({ state, commit }, productoId) {
            return await BonificacionUtilService.obtenerCostos(axios, productoId);
        },

    }
}