<template>
  <card
    class="no-border-card bonificacion"
    body-classes="px-0 pb-1 pt-0 mt-0"
    footer-classes="pb-2"
    headerClasses="py-2 px-2"
  >
    <template v-slot:header>
      <div class="form-inline">
        <div class="form-group bonificacion-tipo p-0 pr-2 mr-4">
          <select
            class="form-control pr-4 py-1 pl-2 border-0 w-100"
            v-model="bonificacion.tipo"
            style="height: 40px"
            :disabled="es_edicion"
          >
            <option v-for="tipo in tipos" :value="tipo.valor" :key="tipo.valor">
              {{ tipo.nombre }}
            </option>
          </select>
        </div>
        <h2
          class="mb-0 pr-2"
          :class="{
            'border-right': bonificacion.tipo == 'escala',
          }"
        >
          Bonificación {{ bonificacion.codigo }}
          <span hidden
            >{{ esta_completa }} {{ costos_por_distribuidoras }}
            {{ todos_productos }}</span
          >
          <small v-if="esta_completa" hidden>
            {{ costos_por_distribuidoras }}
          </small>
        </h2>

        <div
          class="row mx-0 pl-1 d-flex align-items-center"
          v-if="bonificacion.tipo == 'escala'"
        >
          <h3 class="m-auto px-2">Producto:</h3>
          <div
            class="form-check form-check-inline ml-3"
            @click.prevent="bonificacion.escala_individual = false"
          >
            <button
              class="bonificacion-radio-btn p-0 m-0 d-flex justify-content-center align-items-center"
              :class="bonificacion.escala_individual == false ? 'activo' : ''"
            >
              <span class="bonificacion-radio-btn-content"></span>
            </button>
            <label
              class="form-check-label form-control-texto ml-1 pointer"
              for="radio-combinaciones"
            >
              Combinaciones
            </label>
          </div>
          <div
            class="form-check form-check-inline ml-3"
            @click.prevent="bonificacion.escala_individual = true"
          >
            <button
              class="bonificacion-radio-btn p-0 m-0 d-flex justify-content-center align-items-center"
              :class="bonificacion.escala_individual == true ? 'activo' : ''"
            >
              <span class="bonificacion-radio-btn-content"></span>
            </button>
            <label
              class="form-check-label form-control-texto ml-1 pointer"
              for="radio-individual"
            >
              Individual
            </label>
          </div>
        </div>
        <div class="ml-auto">
          <button
            class="btn text-danger"
            @click.prevent="eliminar"
            v-if="!es_edicion"
          >
            <span>Eliminar</span>
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </template>

    <div class="card-body py-2 px-4">
      <div class="row font-weigth-600 chip chip-message-warning" v-if="mensajes_prod.length">
        <div class="col-auto d-flex align-items-center justify-content-center">
          <i class="fas fa-info-circle"></i>
        </div>
        <div class="col-11">
          <ul class="m-0">
            <li v-for="mensaje in mensajes_prod" :key="mensaje" class="list-group-item border-0 p-1" style="background: transparent;">
              {{ mensaje }}
            </li>
          </ul>
        </div>
      </div>

      <form autocomplete="off">
        <Promocion
          v-if="bonificacion.tipo == 'promocion' || !bonificacion.tipo"
          :bonificacion="bonificacion"
          :productos_options="productos_options"
          :es_edicion="es_edicion"
        ></Promocion>
        <Combo
          v-if="bonificacion.tipo == 'combo'"
          :bonificacion="bonificacion"
          :productos_options="productos_options"
          :es_edicion="es_edicion"
        ></Combo>
        <Escala
          v-if="bonificacion.tipo == 'escala'"
          :bonificacion="bonificacion"
          :productos_options="productos_options"
          :es_edicion="es_edicion"
        ></Escala>
        <Rebate
          v-if="bonificacion.tipo == 'rebate'"
          :bonificacion="bonificacion"
          :productos_options="productos_options"
          :es_edicion="es_edicion"
        ></Rebate>

        <SeleccionClientes
          :bonificacion="bonificacion"
          :es_edicion="es_edicion"
        ></SeleccionClientes>

        <div class="row mt-2" v-if="bonificacion.tipo != 'rebate'">
          <div class="col-12 mt-4 d-flex align-items-start px-0">
            <h3 for="" class="m-0 py-1 my-1 mr-3">Resumen</h3>
            <Summary :bonificacion="bonificacion"> </Summary>
          </div>
        </div>
        <div class="row mt-0">
          <div class="col-12 mt-3 d-flex align-items-center px-0">
            <h3 for="" class="m-0">Total clientes:</h3>
            <Chip :texto="contador_clientes"></Chip>
          </div>
        </div>
      </form>
    </div>
  </card>
</template>
<script setup>
import Chip from "./Chip.vue";

import Summary from "./Summary.vue";
import Promocion from "./Promocion.vue";
import Combo from "./Combo.vue";
import Escala from "./Escala.vue";
import Rebate from "./Rebate.vue";
import SeleccionClientes from "./SeleccionClientes.vue";
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import constantes from "@/model/constantes.js";
const store = useStore();

const { bonificacion, tipos, index, puede_eliminar, es_edicion } = defineProps({
  bonificacion: {
    type: Object,
  },
  es_edicion: {
    type: Boolean,
    default: false,
  },
  tipos: {
    type: Array,
    default: [],
  },
  index: {
    type: Number,
  },
});

onMounted(() => {
  setTimeout(() => {
    document.querySelector(".form_laive").classList.remove("validateClient");
  }, 200);
});

const eliminar = () => {
  store.commit("bonificacion/remover", index);
};

const productos = computed(() => store.state.bonificacionUtil.productos);

const contador_clientes = computed(() => {
  if(bonificacion.total_clientes){
    return bonificacion.total_clientes;
  }
  if (bonificacion.tipo == "rebate") {
    return bonificacion.datos_extraidos?.length ?? 0;
  } else {
    bonificacion.clientes_por_distribuidora =
      store.state.bonificacionUtil.distribuidoras_clientes;
    if (bonificacion.distribuidoras) {
      return bonificacion.clientes_por_distribuidora
        .filter((item) =>
          bonificacion.distribuidoras.includes(item.iddistribuidora)
        )
        .reduce((acumulador, objeto) => acumulador + objeto.totalCliente, 0);
    }
    return 0;
  }
});

const productos_options = computed(() =>
  productos.value.map((item) => {
    return {
      value: item.codigofabrica,
      label: `${item.codigofabrica} - ${item.descripcion}`,
    };
  })
);

const validarRegalo = () => {
  return bonificacion.prods_regalo.every((regalo) => {
    return regalo.tipo == constantes.TIPO_REGALO_PRODUCTO
      ? regalo.productos?.length > 0
      : !!regalo.valor;
  });
};

const productos_configurados = computed(() => {
  const descripcion_valida = bonificacion.descripcion.trim().length > 0;
  const fechas_validas = bonificacion.inicio < bonificacion.fin;
  let result = false;
  if (
    [constantes.TIPO_PROMOCION, constantes.TIPO_COMBO].includes(
      bonificacion.tipo
    )
  ) {
    const regalo_valido = validarRegalo();

    const productos_validos = bonificacion.prods_promo.every(
      (item) => item.productos?.length > 0 && item.unidades > 0
    );

    result =
      descripcion_valida &&
      fechas_validas &&
      regalo_valido &&
      productos_validos;
  }
  if (bonificacion.tipo == constantes.TIPO_ESCALA) {
    const escalas_validas =
      bonificacion.prods_promo.length > 0
        ? bonificacion.prods_promo.every((escala) => {
            const almenos_un_detalle = escala.detalles?.some((detalle) => {
              return detalle.bultos > 0 && detalle.descuento > 0;
            });
            return almenos_un_detalle && escala.proposito;
          })
        : false;
    result = escalas_validas && descripcion_valida && fechas_validas;
  }
  if (bonificacion.tipo == constantes.TIPO_REBATE) {
    const regalo_valido = validarRegalo();

    const producto_categorias_validas =
      bonificacion.prods_promo[0].categorias?.length > 0;

    result =
      descripcion_valida &&
      fechas_validas &&
      regalo_valido &&
      producto_categorias_validas;
  }

  bonificacion.productos_configurados = result;
  return result;
});

const esta_completa = computed(() => {
  if (es_edicion) {
    return true;
  }
  let result = false;
  const clientes_configurados =
    bonificacion.carga_masiva == false
      ? bonificacion.distribuidoras.length > 0 &&
        bonificacion.vendedores.length > 0 &&
        bonificacion.tipos_cliente.length > 0 &&
        bonificacion.segmentos.length > 0 &&
        bonificacion.giros_negocio.length > 0 &&
        bonificacion.tipos_vendedor.length > 0
      : bonificacion.tiene_documento_valido == true;

  const no_tiene_mensajes = mensajes_prod.value.length == 0;
  result =
    productos_configurados.value && clientes_configurados && no_tiene_mensajes;
  bonificacion.es_completa = result;
  return result;
});

const mensajes_prod = computed(() => {
  if (es_edicion) {
    return [];
  }
  const elementos_sin_costo = new Set();
  bonificacion.distribuidoras.forEach((dex) => {
    costos_raw.value.forEach((costo_raw) => {
      if (costo_raw.costos?.length) {
        const costo_dex = costo_raw.costos.find(
          (item) => item.idDistribuidor == dex
        );
        if (!costo_dex?.costoBodega) {
          elementos_sin_costo.add(
            `El producto ${costo_raw.id} no tiene costo para la distribuidora ${costo_dex?.abreviacion}`
          );
        }
      } else {
        elementos_sin_costo.add(
          `El producto ${costo_raw.id} no tiene costo para ninguna distribuidora seleccionada`
        );
      }
    });
  });
  return [...elementos_sin_costo];
});

const costos_por_distribuidoras = computed(() => {
  return obtenerCostoPorDex();
});

const obtenerCostoPorDex = () => {
  if (es_edicion) {
    return;
  }
  if (
    bonificacion.tipo == constantes.TIPO_COMBO ||
    bonificacion.tipo == constantes.TIPO_PROMOCION ||
    bonificacion.tipo == constantes.TIPO_REBATE
  ) {
    const distribuidoras = bonificacion.distribuidoras.map((distribuidora) => {
      let costo_productos = 0;
      let costo_regalos = 0;

      let costo_productos_und = 0;
      let costo_regalos_und = 0;

      let costo_regalos_dist = 0;
      let costo_regalos_und_dist = 0;

      bonificacion.prods_promo.forEach((grupo) => {
        const producto = grupo.productos ? grupo.productos[0] : null;
        const unidades = grupo.unidades;
        if (unidades && unidades > 0 && producto) {
          const costo_raw = costos_raw.value.find(
            (item) => item.id == producto
          );
          if (costo_raw) {
            const costo = costo_raw.costos.find(
              (item) => item.idDistribuidor == distribuidora
            );
            const costo_bodega = costo.costoBodega;
            const costo_grupo = parseFloat(unidades) * parseFloat(costo_bodega);
            costo_productos += costo_grupo;
            costo_productos_und = costo_bodega;
          }
        }
      });

      bonificacion.prods_regalo.forEach((grupo) => {
        const producto = grupo.productos[0];
        const unidades = grupo.unidades;
        if (unidades && unidades > 0 && producto) {
          const costo_raw = costos_raw.value.find(
            (item) => item.id == producto
          );
          if (costo_raw) {
            const costo = costo_raw.costos.find(
              (item) => item.idDistribuidor == distribuidora
            );
            const costo_bodega = costo.costoBodega;
            const costo_dist = costo.costoDist;

            const costo_grupo = parseFloat(unidades) * parseFloat(costo_bodega);
            const costo_dist_grupo = parseFloat(unidades) * parseFloat(costo_dist);

            costo_regalos = costo_grupo;
            costo_regalos_und = costo_bodega;

            costo_regalos_dist = costo_dist_grupo;
            costo_regalos_und_dist = costo_dist;
          }
        }
      });
      return {
        distribuidora: distribuidora,
        costo_productos,
        costo_productos_und,
        costo_regalos,
        costo_regalos_und,
        costo_regalos_dist,
        costo_regalos_und_dist
      };
    });
    bonificacion.costos_por_dex = distribuidoras;
    return distribuidoras;
  }
  return null;
};

const costos_raw = ref([]);

const todos_productos = computed(() => {
  const all_productos = bonificacion.prods_promo.flatMap(
    (grupo) => grupo.productos
  );
  const all_regalos = bonificacion.prods_regalo
    .filter((grupo) => grupo.tipo == constantes.TIPO_REGALO_PRODUCTO)
    .flatMap((grupo) => grupo.productos);

  const union = [...all_productos, ...all_regalos];
  const all = [...new Set(union)];

  costos_raw.value = costos_raw.value.filter((item) => all.includes(item.id));
  all.forEach(async (element) => {
    if (!element) {
      return;
    }
    const existente = costos_raw.value.find((item) => item.id == element);
    if (!existente) {
      await store
        .dispatch("bonificacionUtil/obtenerCostos", element)
        .then((result) => {
          if (result == "el producto no existe") {
            result = [];
          }

          costos_raw.value.push({
            id: element,
            costos: result,
          });

          bonificacion.costos_raw = costos_raw.value;
        });
    }
  });
  bonificacion.costos_raw = costos_raw.value;
});
</script>
<style lang="scss" scoped>
.bonificacion {
  &-tipo {
    border: 2px solid #02a54f;
    border-radius: 8px;

    select {
      font-weight: bold;
      font-size: 1rem;
      color: #02a54f;
    }
  }

  input[type="radio"]::after {
    width: 15px;
    height: 15px;
    position: relative;
    background: transparent;
    border: 1px solid red;
    border-radius: 15px;
    content: "";
    display: inline-block;
  }

  input[type="radio"]:checked {
    background-color: green; /* Cambiar el color cuando está seleccionado */
  }
  .pointer {
    cursor: pointer;
  }
}

.btn-link {
  text-decoration: underline !important;
  color: #02a54f !important;
}
.btn-success {
  background: #02a54f;
}

.btn {
  border-radius: 10px !important;
}
</style>
