import { createRouter, createWebHistory } from "vue-router";

import { store } from "../store/index";

import WrapperRouter from "../components/WrapperRouter.vue";

import DashboardLayout from "@/views/Layout/DashboardLayout";
import AuthLayout from "@/views/Pages/AuthLayout";

// Dashboard pages
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Widgets from "../views/Widgets.vue";
import Charts from "../views/Charts.vue";
import Calendar from "../views/Calendar/Calendar.vue";

// Pages
import Pricing from "../views/Pages/Pricing.vue";
import Login from "../views/Pages/Login.vue";
import Register from "../views/Pages/Register.vue";
import Lock from "../views/Pages/Lock.vue";
import Profile from "../views/Pages/UserProfile.vue";
import Timeline from "../views/Pages/TimeLinePage.vue";

// Components pages
import Buttons from "../views/Components/Buttons.vue";
import Cards from "../views/Components/Cards.vue";
import Grid from "../views/Components/GridSystem.vue";
import Notifications from "../views/Components/Notifications.vue";
import Icons from "../views/Components/Icons.vue";
import Typography from "../views/Components/Typography.vue";

// Tables pages
import RegularTables from "../views/Tables/RegularTables.vue";
import SortableTables from "../views/Tables/SortableTables.vue";
import PaginatedTables from "../views/Tables/PaginatedTables.vue";

// Maps pages
import GoogleMaps from "../views/Maps/GoogleMaps.vue";
import VectorMaps from "../views/Maps/VectorMaps.vue";

// Forms pages
import FormElements from "../views/Forms/FormElements.vue";
import FormComponents from "../views/Forms/FormComponents.vue";
import FormValidation from "../views/Forms/FormValidation.vue";

// Usuarios Backoffice
import ListUsuarios from "../views/Usuarios/List.vue";
import CreateUsuarios from "../views/Usuarios/Create.vue";
import EditarUsuarios from "../views/Usuarios/Edit.vue";
import UsuariosManagementTrade from "../views/Bonificaciones/GestionUsuarios.vue";

// Bonificaciones
import ListBonificaciones from "../views/Bonificaciones/Index.vue";
import BonificacionesCreate from "../views/Bonificaciones/Create.vue";
import BonificacionesEdit from "../views/Bonificaciones/Edit.vue";
import ListaLiquidaciones from "../views/Liquidaciones/Index.vue";

// Maestro Productos Page
import ListadoProducts from "../views/MaestroProductos/List.vue";
import EditProduct from "../views/MaestroProductos/Edit.vue";
import CreateProduct from "../views/MaestroProductos/Create.vue";
import Jerarquias from "../views/MaestroProductos/Jerarquias.vue";
import JerarquiaEdit from "../views/MaestroProductos/JerarquiaEdit.vue";
import JerarquiaCreate from "../views/MaestroProductos/JerarquiaCreate.vue";
import Promociones from "../views/MaestroProductos/Promociones.vue";
import PromocionCreate from "../views/MaestroProductos/PromocionCreate.vue";
import PromocionEdit from "../views/MaestroProductos/PromocionEdit.vue";
import ListadoProductosFoco from "../views/MaestroProductos/ProductosFoco.vue";
import CargaProductosFoco from "../views/MaestroProductos/ImportProductosFoco.vue";
import ListadoProductosEspeciales from "../views/MaestroProductos/ProductosEspeciales.vue";
import CargaProductosEspecial from "../views/MaestroProductos/ImportProductosEspeciales.vue";
import ListadoProductosCluster from "../views/MaestroProductos/ProductosCluster.vue";
import ProductosClusterCreate from "../views/MaestroProductos/ProductoClusterCreate.vue";
import CargaProductosRefrigerados from "../views/MaestroProductos/ImportProductosRefrigerados.vue";
import ListadoProductosRefrigerados from "../views/MaestroProductos/ProductosRefrigerados.vue";
import ListadoProductosRecomendados from "../views/MaestroProductos/ProductosRecomendados.vue";
import RecomendacionesVendedor from "../views/MaestroProductos/RecomendacionesVendedor.vue";
import RecomendacionesCategoria from "../views/MaestroProductos/RecomendacionesCategoria.vue";
import ExportProductosRecomendados from "../views/MaestroProductos/ExportProductosRecomendados.vue";
import ListadoProductosPrioridad from "../views/MaestroProductos/ProductosPrioridad.vue";
import CargaProductosPrioridad from "../views/MaestroProductos/ImportProductosPrioridad.vue";

// Maestro Categorias Page
import ListadoCategorias from "../views/MaestroCategorias/List.vue";
import EditCategory from "../views/MaestroCategorias/Edit.vue";
import CreateCategory from "../views/MaestroCategorias/Create.vue";

// Maestro de Clientes
import ListadoClientes from "../views/MaestroClientes/List.vue";
import SolicitudesClientes from "../views/MaestroClientes/Solicitudes.vue";
import CargarEquiposFrio from "../views/MaestroClientes/ImportEquipos.vue";
import DetalleCliente from "../views/MaestroClientes/Detalle.vue";

// Maestro Supervisores
import ListadoSupervisores from "../views/MaestroSupervisores/List.vue";

// Maestro Vendedores
import ListadoVendedores from "../views/MaestroVendedores/List.vue";
import DetalleVendedor from "../views/MaestroVendedores/Detail.vue";
import Geocercas from "../views/MaestroVendedores/Geocercas.vue";
import GeocercaImport from "../views/MaestroVendedores/GeocercaImport.vue";

// Maestro Pedidos
import ListadoPedidos from "../views/Pedidos/List.vue";
import ListadoPedidosEstadisticas from "../views/Pedidos/Estadisticas.vue";
import ReportePedidos from "../views/Pedidos/Reporte.vue";
import ListadoPromocionesTruncas from "../views/Pedidos/PromocionesTruncas.vue";
import ListadoPedidoMinimo from "../views/Pedidos/PedidoMinimo.vue";
import CrearPedidoMinimo from "../views/Pedidos/CrearPedidoMinimo.vue";
import EditarPedidoMinimo from "../views/Pedidos/EditarPedidoMinimo.vue";

// No Pedidos
import ListadoNoPedidos from "../views/NoPedidos/List.vue";

// No Pedidos
import ListaPedidosPerdidos from "../views/PedidosPerdidos/List.vue";

// Maestro Cobranzas
import ListadoCobranzas from "../views/Cobranzas/List.vue";
import ReporteCobranzas from "../views/Cobranzas/Reporte.vue";

// Maestro Depositos
import ListadoDepositos from "../views/Depositos/List.vue";

// Hoja de Ruta
import RutaRecorrida from "../views/HojadeRuta/RutaRecorrida.vue";
import UbicacionRecorrida from "../views/HojadeRuta/UbicacionRecorrida.vue";

// Maestro Devoluciones
import ListadoDevoluciones from "../views/Devoluciones/List.vue";

// Codigos de Verificacion
import ListadoCodigos from "../views/CodigosVerificacion/List.vue";

// Reportes
import ReporteVentasVendedor from "../views/Reportes/ReporteVentas/Vendedores";
import ReporteVentasCategoria from "../views/Reportes/ReporteVentas/Categoria";
import ReporteVentasObjetivo from "../views/Reportes/ReporteVentas/Objetivo";
import ReporteVisitasVendedor from "../views/Reportes/ReporteVisitas/Vendedores";
import ReporteCoberturaVendedor from "../views/Reportes/ReporteCobertura/Vendedores";
import ReporteCoberturaPesos from "../views/Reportes/ReporteCobertura/Pesos";
import ReporteCoberturaPesosImport from "../views/Reportes/ReporteCobertura/ImportPesos";
import ReporteCoberturaAgregadaVendedor from "../views/Reportes/ReporteCoberturaAgregada/Vendedores";
import ReporteCoberturaAgregadaCategoria from "../views/Reportes/ReporteCoberturaAgregada/Categoria";
import ReporteCoberturaAgregadaPesos from "../views/Reportes/ReporteCoberturaAgregada/Pesos";
import ReporteCoberturaAgregadaObjetivo from "../views/Reportes/ReporteCoberturaAgregada/Objetivo";
import ReporteCoberturaAgregadaSku from "../views/Reportes/ReporteCoberturaAgregada/Sku";
import ReporteCoberturaAgregadaPesosSku from "../views/Reportes/ReporteCoberturaAgregada/PesosSku";
import ReporteCoberturaAgregadaObjetivoSku from "../views/Reportes/ReporteCoberturaAgregada/ObjetivoSku";
import ReporteCrecimientoVendedor from "../views/Reportes/ReporteCrecimiento/Vendedores";
import ReporteCrecimientoCategoria from "../views/Reportes/ReporteCrecimiento/Categoria";
import ReporteCobrosMes from "../views/Reportes/ReporteCobros/Mes";
import ReporteMaximoDevolucionesCategoria from "../views/Reportes/ReporteMaximoDevoluciones/Categoria";
import ReporteMaximoDevolucionesVendedor from "../views/Reportes/ReporteMaximoDevoluciones/Vendedor";
import ReporteMaximoDevolucionesObjetivos from "../views/Reportes/ReporteMaximoDevoluciones/Objetivo";
import ReporteSurtidoVendedores from "../views/Reportes/ReporteSurtidos/Vendedores";
import ReporteSurtidoPorcentaje from "../views/Reportes/ReporteSurtidos/Porcentajes";
import ReporteTicketEsperado from "../views/Reportes/ReportTicketEsperado/TicketEsperado";
import ReportePromociones from "../views/Reportes/ReportPromociones/Promociones";

// Encuestas y Formularios
import Estudios from "../views/FormulariosEncuestas/List.vue";
import EstudioDetalle from "../views/FormulariosEncuestas/Detalle.vue";
import EncuestaEditar from "../views/FormulariosEncuestas/EncuestaEditar.vue";
import FormularioEditar from "../views/FormulariosEncuestas/FormularioEditar.vue";
import EstudioDetalleVendedor from "../views/FormulariosEncuestas/DetalleVendedor.vue";
import EstudioCrear from "../views/FormulariosEncuestas/EstudioCrear.vue";
import EncuestaCrear from "../views/FormulariosEncuestas/EncuestaCrear.vue";
import FormularioCrear from "../views/FormulariosEncuestas/FormularioCrear.vue";

// Parametros Page
import ParametersLogin from "../views/Parameters/Login.vue";
import ParametersProducts from "../views/Parameters/Products.vue";
import ParametersDevolucion from "../views/Parameters/Devolucion.vue";
import ParametersDepositos from "../views/Parameters/Depositos.vue";
import ParametersTomapedidos from "../views/Parameters/TomaPedidos.vue";
import ParametersHojaderuta from "../views/Parameters/HojaDeRuta.vue";
import ParametersReportes from "../views/Parameters/Reportes.vue";
import ParametersAlertas from "../views/Parameters/Alertas.vue";
import ParametersNotificaciones from "../views/Parameters/Notificaciones.vue";
import ParametersOperacionesActivos from "../views/Parameters/OperacionesActivos.vue";
import ParametersEnvioErrores from "../views/Parameters/EnvioErrores.vue";
import ParametersTicketEsperado from "../views/Parameters/TicketEsperado.vue";
import ParametersProductosRefrigerados from "../views/Parameters/ProductosRefrigerados.vue";
import ParametersRegistrosPedidos from "../views/Parameters/RegistroPedidos.vue";
import ParametersCobranzaFueraDeRuta from "../views/Parameters/CobranzaFueraDeRuta.vue";
import ParametersCreacionClientes from "../views/Parameters/CreacionClientes.vue";
import ParametersRecenciaCompra from "../views/Parameters/RecenciaCompra.vue";
import ParametersProductosRecomendados from "../views/Parameters/ProductosRecomendados.vue";
import ParametersNotasCredito from "../views/Parameters/NotasCredito.vue";
import ParametersMetodosDePago from "../views/Parameters/MetodosDePago.vue";
import ParametersCrearMetodosDePago from "../views/Parameters/CrearMetodosDePago.vue";
import ParametersStockReservado from "../views/Parameters/StockReservado.vue";
import ParametersEquipoFrio from "../views/Parameters/EquipoFrio.vue";

//Notificaciones
import ListadoNotificaciones from "../views/Notificaciones/List.vue";
import ListadoNotificacionesV2 from "../views/Notificaciones/v2.vue";
import VisualizacionTiempoReal from "../views/TiempoReal/Maps";

// Versiones del App
import VersionesAppList from "../views/VersionesApp/List.vue";
import VersionesAppCreate from "../views/VersionesApp/Crear.vue";

// Maestros
import MaestrosListado from "../views/Maestros/List.vue";
import MaestrosEdit from "../views/Maestros/Edit.vue";
import MaestroQuestionClusterEdit from "../views/Maestros/QuestionClusterEdit.vue";
import MaestroMercadoEdit from "../views/Maestros/MercadoEdit.vue";
import MaestrosCreate from "../views/Maestros/Create.vue";

// Logs
import LogsList from "../views/LogsApp/List.vue";

// Catalogo de Errores
import CatalogoList from "../views/CatalogoErrores/List.vue";

//incidencias
import ListadoIncidencias from "../views/Pedidos/Incidencias.vue";
import ImportEstadosIncidencias from "../views/Incidencias/UpdateState.vue";

// Equipos de frio
import Solicitudes from "../views/EquiposFrio/Solicitudes.vue";
import IncidenciasPage from "../views/EquiposFrio/Incidencias/IncidenciasPage.vue";
import PedidoMinimoPage from "../views/EquiposFrio/PedidoMinimo/PedidoMinimoPage.vue";
import MaestroActivosPage from "../views/EquiposFrio/MaestroActivos/MaestroActivosPage.vue";

let componentsMenu = {
	path: "/components",
	component: DashboardLayout,
	redirect: "/components/buttons",
	name: "Components",
	children: [
		{
			path: "buttons",
			name: "Buttons",
			components: { default: Buttons },
		},
		{
			path: "cards",
			name: "Cards",
			components: { default: Cards },
		},
		{
			path: "grid-system",
			name: "Grid",
			components: { default: Grid },
		},
		{
			path: "notifications",
			name: "Notifications",
			components: { default: Notifications },
		},
		{
			path: "icons",
			name: "Icons",
			components: { default: Icons },
		},
		{
			path: "typography",
			name: "Typography",
			components: { default: Typography },
		},
	],
};

let tablesMenu = {
	path: "/",
	component: DashboardLayout,
	redirect: "/regular",
	name: "Listas",
	meta: { requiresAuth: true },
	children: [
		{
			path: "regular",
			name: "regular",
			components: { default: RegularTables },
		},
		{
			path: "sortable",
			name: "Sortable",
			components: { default: SortableTables },
		},
		{
			path: "paginated",
			name: "Paginated",
			components: { default: PaginatedTables },
		},
	],
};

let mapsMenu = {
	path: "/maps",
	component: DashboardLayout,
	redirect: "/maps/google",
	name: "Maps",
	children: [
		{
			path: "google",
			name: "Google Maps",
			components: { default: GoogleMaps },
		},
		{
			path: "vector",
			name: "Vector Map",
			components: { default: VectorMaps },
		},
	],
};

let formsMenu = {
	path: "/forms",
	component: DashboardLayout,
	redirect: "/forms/elements",
	name: "Forms",
	children: [
		{
			path: "elements",
			name: "Forms elements",
			components: { default: FormElements },
		},
		{
			path: "components",
			name: "Forms Components",
			components: { default: FormComponents },
		},
		{
			path: "validation",
			name: "Forms Validation",
			components: { default: FormValidation },
		},
	],
};
// Routes Laive
let usuariosBackoffice = {
	path: "/usuarios",
	component: DashboardLayout,
	redirect: "/usuarios/listado",
	name: "Usuarios",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Listado de Usuarios",
			components: { default: ListUsuarios },
		},
		{
			path: "crear",
			name: "Crear Usuario",
			components: { default: CreateUsuarios },
		},
		{
			path: "editar/:id/:code/:dis/:rol",
			name: "Editar Usuario",
			components: { default: EditarUsuarios },
		},

		{
			path: "administracion-trade",
			name: "Administracion Trade",
			components: { default: UsuariosManagementTrade },
		},
	],
};

const showBonification = process.env.VUE_APP_ENABLE_BONIFICATION === "1";

const bonificacionesBackoffice = showBonification ? {
	path: "/bonificaciones",
	component: DashboardLayout,
	redirect: "/bonificaciones/listado",
	name: "Bonificaciones",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Listado de bonificaciones",
			components: { default: ListBonificaciones },
		},
		{
			path: "crear",
			name: "Crear bonificaciones",
			components: { default: BonificacionesCreate },
		},
		{
			path: "editar/:lista/:ids",
			name: "Editar bonificaciones",
			components: { default: BonificacionesEdit },
		},
	],
} : {};

const liquidaciones = {
	path: "/liquidaciones",
	component: DashboardLayout,
	redirect: "/liquidaciones/listado",
	name: "Liquidaciones",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Listado de liquidaciones",
			components: { default: ListaLiquidaciones },
		},
	],
};

let maestroProductos = {
	path: "/maestro-productos",
	redirect: "/maestro-productos/listado",
	name: "Maestro Productos",
	meta: { requiresAuth: true },
	component: DashboardLayout,
	children: [
		{
			path: "listado",
			name: "Productos",
			components: { default: ListadoProducts },
		},
		{
			path: "editar/",
			redirect: "/maestro-productos/listado",
		},
		{
			path: "editar/:id",
			name: "Editar Producto",
			components: { default: EditProduct },
		},
		{
			path: "crear/",
			name: "Crear Producto",
			components: { default: CreateProduct },
		},
		{
			path: "jerarquias",
			name: "Jerarquía de Productos",
			components: { default: Jerarquias },
		},
		{
			path: "jerarquias/crear",
			name: "Crear Jerarquía",
			components: { default: JerarquiaCreate },
		},
		{
			path: "promociones",
			name: "Promociones",
			components: { default: Promociones },
		},
		{
			path: "promociones/crear",
			name: "Crear Promocion",
			components: { default: PromocionCreate },
		},
		{
			path: "promociones/editar/:id",
			name: "Editar Promocion",
			components: { default: PromocionEdit },
		},
		{
			path: "jerarquias/editar/:id",
			name: "Editar Jerarquía",
			components: { default: JerarquiaEdit },
		},
		{
			path: "listado-productos-foco",
			name: "Productos Foco",
			components: { default: ListadoProductosFoco },
		},
		{
			path: "carga-productos-foco",
			name: "Carga Productos Foco",
			components: { default: CargaProductosFoco },
		},
		{
			path: "listado-productos-especiales",
			name: "Productos Especiales",
			components: { default: ListadoProductosEspeciales },
		},
		{
			path: "carga-productos-especiales",
			name: "Carga Productos Especiales",
			components: { default: CargaProductosEspecial },
		},
		{
			path: "listado-productos-cluster",
			name: "Productos Cluster",
			components: { default: ListadoProductosCluster },
		},
		{
			path: "listado-productos-cluster/crear",
			name: "Crear Producto Cluster",
			components: { default: ProductosClusterCreate },
		},
		{
			path: "carga-productos-refrigerados",
			name: "Carga Productos Refrigerados",
			components: { default: CargaProductosRefrigerados },
		},
		{
			path: "listado-productos-refrigerados",
			name: "Productos Refrigerados",
			components: { default: ListadoProductosRefrigerados },
		},
		{
			path: "listado-productos-recomendados",
			name: "Productos Recomendados",
			components: { default: ListadoProductosRecomendados },
		},
		{
			path: "recomendaciones-vendedor",
			name: "Recomendaciones por Vendedor",
			components: { default: RecomendacionesVendedor },
		},
		{
			path: "recomendaciones-categoria",
			name: "Recomendaciones por Categoria",
			components: { default: RecomendacionesCategoria },
		},
		{
			path: "exportar-productos-recomendados",
			name: "Exportar Productos Recomendados",
			components: { default: ExportProductosRecomendados },
		},
		{
			path: "productos-prioridad",
			name: "Productos Prioridad",
			components: { default: ListadoProductosPrioridad },
		},
		{
			path: "carga-productos-prioridad",
			name: "Carga Productos Prioridad",
			components: { default: CargaProductosPrioridad },
		},
	],
};
let maestroCategorias = {
	path: "/maestro-categorias",
	component: DashboardLayout,
	redirect: "/maestro-categorias/listado",
	name: "Maestro Categorias",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Categorias",
			components: { default: ListadoCategorias },
		},
		{
			path: "editar/",
			redirect: "/maestro-categorias/listado",
		},
		{
			path: "editar/:id",
			name: "Editar Categoria",
			components: { default: EditCategory },
		},
		{
			path: "crear/",
			name: "Crear Categoria",
			components: { default: CreateCategory },
		},
	],
};

let maestroClientes = {
	path: "/clientes",
	component: DashboardLayout,
	redirect: "/clientes/listado",
	name: "Clientes",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Listado Clientes",
			components: { default: ListadoClientes },
		},
		{
			path: "detalle",
			redirect: "/clientes/listado",
		},
		{
			path: "detalle/:id",
			name: "Detalle Cliente",
			components: { default: DetalleCliente },
		},
		{
			path: "solicitudes",
			name: "Nuevos Clientes",
			components: { default: SolicitudesClientes },
		},
		{
			path: "cargar-equipos-frio",
			name: "Cargar Equipos de Frio",
			components: { default: CargarEquiposFrio },
		},
	],
};


const equiposFriosRouter = {
	path: "/equipos-frio",
	component: DashboardLayout,
	redirect: "/equipos-frio/maestro-activos",
	name: "Equipos de Frío",
	meta: { requiresAuth: true },
	children: [
    {
      path: "maestro-activos",
		  name: "Maestro de activos",
      component: MaestroActivosPage,
    },
    {
      path: "pedido-minimo",
      name: "Pedido mínimo",
      component: PedidoMinimoPage,
    },
    {
      path: "solicitudes",
      name: "Solicitudes de activos",
      component: Solicitudes,
    },
    {
      path: "incidencias",
      meta: {
        refName: "Incidencias",
      },
      component: IncidenciasPage,
    },
  ],
};

let maestroSupervisores = {
	path: "/supervisores",
	component: DashboardLayout,
	redirect: "/supervisores/listado",
	name: "Supervisores",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Listado Supervisores",
			components: { default: ListadoSupervisores },
		},
	],
};

let maestroVendedores = {
	path: "/vendedores",
	component: DashboardLayout,
	redirect: "/vendedores/listado",
	name: "Vendedores",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Listado Vendedores",
			components: { default: ListadoVendedores },
		},
		{
			path: "detalle",
			redirect: "/vendedores/listado",
		},
		{
			path: "listado-geocercas",
			name: "Geocercas",
			components: { default: Geocercas },
		},
		{
			path: "geocerca-importar",
			name: "Geocerca Importar",
			components: { default: GeocercaImport },
		},
		{
			path: "detalle/:id",
			name: "Detalle Vendedor",
			components: { default: DetalleVendedor },
		},
	],
};

let maestroPedidos = {
	path: "/pedidos",
	component: DashboardLayout,
	redirect: "/pedidos/listado",
	name: "Pedidos",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Listado de Pedidos",
			components: { default: ListadoPedidos },
		},
		{
			path: "estadisticas",
			name: "Estadisticas",
			components: { default: ListadoPedidosEstadisticas },
		},
		{
			path: "reporte",
			name: "Reporte de Pedidos",
			components: { default: ReportePedidos },
		},
		{
			path: "listado-incidencias",
			name: "Solicitudes de Incidencias",
			components: { default: ListadoIncidencias },
		},
		{
			path: "promociones-truncas",
			name: "Promociones Truncas",
			components: { default: ListadoPromocionesTruncas },
		},
		{
			path: "pedido-minimo",
			name: "Pedido minimo",
			components: { default: ListadoPedidoMinimo },
		},
		{
			path: "crear-pedido-minimo",
			name: "Crear Pedido minimo",
			components: { default: CrearPedidoMinimo },
		},
		{
			path: "pedido-minimo/editar/:id",
			name: "Editar Pedido minimo",
			components: { default: EditarPedidoMinimo },
		},
	],
};

let maestroNoPedidos = {
	path: "/",
	component: DashboardLayout,
	name: "No Pedidos",
	meta: { requiresAuth: true },
	children: [
		{
			path: "nopedidos",
			name: "Listado No Pedidos",
			components: { default: ListadoNoPedidos },
		},
	],
};

let maestroPedidosPerdidos = {
	path: "/",
	component: DashboardLayout,
	name: "Pedidos no procesados",
	meta: { requiresAuth: true },
	children: [
		{
			path: "pedidos-no-procesados",
			name: "Listado Pedidos no procesados",
			components: { default: ListaPedidosPerdidos },
		},
	],
};

let maestroCobranzas = {
	path: "/cobranzas",
	component: DashboardLayout,
	redirect: "/cobranzas/listado",
	name: "Cobranzas",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Listado de Cobranzas",
			components: { default: ListadoCobranzas },
		},
		{
			path: "reporte",
			name: "Reporte de Cobranzas",
			components: { default: ReporteCobranzas },
		},
	],
};

let maestroDepositos = {
	path: "/depositos",
	component: DashboardLayout,
	redirect: "/depositos/listado",
	name: "Depositos",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Listado de Depositos",
			components: { default: ListadoDepositos },
		},
	],
};

let maestroHojadeRuta = {
	path: "/hoja-de-ruta",
	component: DashboardLayout,
	redirect: "/hoja-de-ruta/ruta-recorrida",
	name: "Hoja de ruta",
	meta: { requiresAuth: true },
	children: [
		{
			path: "ruta-recorrida",
			name: "Ruta Recorrida",
			components: { default: RutaRecorrida },
		},
		{
			path: "ubicacion-recorrida",
			name: "Ubicacion Recorrida",
			components: { default: UbicacionRecorrida },
		},
	],
};

let maestroDevoluciones = {
	path: "/devoluciones",
	component: DashboardLayout,
	redirect: "/devoluciones/listado",
	name: "Devoluciones",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Listado de Devoluciones",
			components: { default: ListadoDevoluciones },
		},
	],
};

let codigosVerificacion = {
	path: "/codigos-verificacion",
	component: DashboardLayout,
	redirect: "/codigos-verificacion/listado",
	name: "Codigos",
	meta: { requiresAuth: true },
	children: [
		{
			path: "listado",
			name: "Codigos de Verificacion",
			components: { default: ListadoCodigos },
		},
	],
};

let Reportes = {
	path: "/reportes",
	component: DashboardLayout,
	redirect: "/reportes/ventas-vendedor",
	name: "Reportes",
	meta: { requiresAuth: true },
	children: [
		{
			path: "ventas-vendedor",
			name: "Ventas Vendedor",
			components: { default: ReporteVentasVendedor },
		},
		{
			path: "ventas-categoria",
			name: "Ventas Categoria",
			components: { default: ReporteVentasCategoria },
		},
		{
			path: "ventas-objetivos-vendedor",
			name: "Ventas Objetivos por Vendedor",
			components: { default: ReporteVentasObjetivo },
		},
		{
			path: "visitas-vendedor",
			name: "Visitas Vendedor",
			components: { default: ReporteVisitasVendedor },
		},
		{
			path: "cobertura-vendedor",
			name: "Cobertura Vendedor",
			components: { default: ReporteCoberturaVendedor },
		},
		{
			path: "cobertura-pesos",
			name: "Cobertura Categoria Pesos",
			components: { default: ReporteCoberturaPesos },
		},
		{
			path: "cobertura-import-pesos",
			name: "Cobertura Simple Pesos Importar",
			components: { default: ReporteCoberturaPesosImport },
		},
		{
			path: "cobertura-agregada-vendedor",
			name: "Cobertura Agregada Vendedor",
			components: { default: ReporteCoberturaAgregadaVendedor },
		},
		{
			path: "cobertura-agregada-categoria",
			name: "Cobertura Agregada Categoria",
			components: { default: ReporteCoberturaAgregadaCategoria },
		},
		{
			path: "cobertura-agregada-pesos",
			name: "Cobertura Agregada Pesos",
			components: { default: ReporteCoberturaAgregadaPesos },
		},
		{
			path: "cobertura-agregada-objetivo",
			name: "Cobertura Agregada Objetivo",
			components: { default: ReporteCoberturaAgregadaObjetivo },
		},
		{
			path: "cobertura-agregada-sku",
			name: "Cobertura Agregada Sku",
			components: { default: ReporteCoberturaAgregadaSku },
		},
		{
			path: "cobertura-agregada-pesos-sku",
			name: "Cobertura Agregada Pesos Sku",
			components: { default: ReporteCoberturaAgregadaPesosSku },
		},
		{
			path: "cobertura-agregada-objetivo-sku",
			name: "Cobertura Agregada Objetivo Sku",
			components: { default: ReporteCoberturaAgregadaObjetivoSku },
		},
		{
			path: "cobertura-crecimiento-vendedor",
			name: "Cobertura Crecimiento Vendedor",
			components: { default: ReporteCrecimientoVendedor },
		},
		{
			path: "cobertura-crecimiento-categoria",
			name: "Cobertura Crecimiento Categoria",
			components: { default: ReporteCrecimientoCategoria },
		},
		{
			path: "cobros-mes",
			name: "Cobros por mes",
			components: { default: ReporteCobrosMes },
		},
		{
			path: "maximo-devoluciones-categoria",
			name: "Maximo de Devoluciones por Categoria",
			components: { default: ReporteMaximoDevolucionesCategoria },
		},
		{
			path: "maximo-devoluciones-vendedor",
			name: "Maximo de Devoluciones por Vendedor",
			components: { default: ReporteMaximoDevolucionesVendedor },
		},
		{
			path: "maximo-devoluciones-objetivos",
			name: "Maximo de Devoluciones Objetivos",
			components: { default: ReporteMaximoDevolucionesObjetivos },
		},
		{
			path: "surtidos-vendedor",
			name: "Surtidos Vendedor",
			components: { default: ReporteSurtidoVendedores },
		},
		{
			path: "surtidos-porcentajes",
			name: "Surtidos Objetivos",
			components: { default: ReporteSurtidoPorcentaje },
		},
		{
			path: "ticket-esperado",
			name: "Ticket Esperado",
			components: { default: ReporteTicketEsperado },
		},
		{
			path: "promociones",
			name: "Reporte de Promociones",
			components: { default: ReportePromociones },
		},
	],
};

let parametrosMenu = {
	path: "/parametros",
	redirect: "/parametros/login",
	component: DashboardLayout,
	name: "Parametros",
	meta: { requiresAuth: true },
	children: [
		{
			path: "login",
			name: "Parametros Login",
			components: { default: ParametersLogin },
		},
		{
			path: "productos",
			name: "Parametros Productos",
			components: { default: ParametersProducts },
		},
		{
			path: "devolucion",
			name: "Parametros Devolucion",
			components: { default: ParametersDevolucion },
		},
		{
			path: "depositos",
			name: "Parametros Depositos",
			components: { default: ParametersDepositos },
		},
		{
			path: "tomapedidos",
			name: "Parametros Tomapedidos",
			components: { default: ParametersTomapedidos },
		},
		{
			path: "hojaderuta",
			name: "Parametros Hoja de Ruta",
			components: { default: ParametersHojaderuta },
		},
		{
			path: "reportes",
			name: "Parametros de Reportes",
			components: { default: ParametersReportes },
		},
		{
			path: "alertas",
			name: "Notificaciones de Control",
			components: { default: ParametersAlertas },
		},
		{
			path: "notificaciones",
			name: "Notificaciones Operativas",
			components: { default: ParametersNotificaciones },
		},
		{
			path: "operaciones-activos",
			name: "Operaciones Activos",
			components: { default: ParametersOperacionesActivos },
		},
		{
			path: "envio-erorres",
			name: "Envio de Errores",
			components: { default: ParametersEnvioErrores },
		},
		{
			path: "ticket-esperado",
			name: "Parametros Ticket Esperado",
			components: { default: ParametersTicketEsperado },
		},
		{
			path: "productos-refrigerados",
			name: "Parametros productos regrigerados",
			components: { default: ParametersProductosRefrigerados },
		},
		{
			path: "registro-pedidos",
			name: "Parametros registros pedidos",
			components: { default: ParametersRegistrosPedidos },
		},
		{
			path: "cobranza-fuera-ruta",
			name: "Parametros Cobranza fuera de ruta",
			components: { default: ParametersCobranzaFueraDeRuta },
		},
		{
			path: "notificacion-nuevo-cliente",
			name: "Notificacion nuevo cliente",
			components: { default: ParametersCreacionClientes },
		},
		{
			path: "recencia-compra",
			name: "Parametros recencia de compra",
			components: { default: ParametersRecenciaCompra },
		},
		{
			path: "productos-recomendados",
			name: "Parametros productos recomendados",
			components: { default: ParametersProductosRecomendados },
		},
		{
			path: "notas-credito",
			name: "Parametros notas de credito",
			components: { default: ParametersNotasCredito },
		},
		{
			path: "metodos-pago",
			name: "Metodo de pago QR",
			components: { default: ParametersMetodosDePago },
		},
		{
			path: "crear-metodos-pago",
			name: "Crear metodo de pago",
			components: { default: ParametersCrearMetodosDePago },
		},
		{
			path: "stock-reservado",
			name: "Stock reservado",
			components: { default: ParametersStockReservado },
		},
		{
			path: "equipos-frio",
			name: "Parámetros de equipos de frío",
			component: ParametersEquipoFrio,
		},
	],
};

let notificaciones = {
	path: "/notificaciones",
	redirect: "/notificaciones/listado",
	name: "Notificaciones",
	meta: { requiresAuth: true },
	component: DashboardLayout,
	children: [
		{
			path: "listado",
			name: "Listado de notificaciones",
			components: { default: ListadoNotificaciones },
		},
	],
};
let notificaciones_v2 = {
	path: "/notificaciones/v2",
	redirect: "/notificaciones/v2/listado",
	name: "Notificaciones V2",
	meta: { requiresAuth: true },
	component: DashboardLayout,
	children: [
		{
			path: "listado",
			name: "Listado de notificaciones v2",
			components: { default: ListadoNotificacionesV2 },
		},
	],
};
let tiemporeal = {
	path: "/tiemporeal",
	redirect: "/tiemporeal/maps",
	name: "Tiempo Real",
	meta: { requiresAuth: true },
	component: DashboardLayout,
	children: [
		{
			path: "maps",
			name: "Visualización en tiempo real",
			components: { default: VisualizacionTiempoReal },
		},
	],
};
let formulariosyEncuestas = {
	path: "/formularios-encuestas",
	component: DashboardLayout,
	redirect: "/formularios-encuestas/reporte-encuestas",
	name: "Formularios y Encuestas",
	meta: { requiresAuth: true },
	children: [
		{
			path: "reporte-encuestas",
			name: "Reporte de Encuestas",
			component: Estudios,
		},
		{
			path: "reporte-encuestas/detalle/:id",
			name: "Detalle de estudio",
			component: EstudioDetalle,
		},
		{
			path: "reporte-encuestas/editar-encuesta/:id",
			name: "Editar Encuesta",
			component: EncuestaEditar,
		},
		{
			path: "reporte-encuestas/editar-formulario/:id",
			name: "Editar Formulario",
			component: FormularioEditar,
		},
		{
			path: "reporte-encuestas/detalle/:id/vendedor/:idVendedor",
			name: "Detalle de estudio Vendedor",
			component: EstudioDetalleVendedor,
		},
		{
			path: "reporte-encuestas/crear",
			name: "Crear Estudio",
			component: EstudioCrear,
		},
		{
			path: "reporte-encuestas/crear-encuesta/:id",
			name: "Crear Encuesta",
			component: EncuestaCrear,
		},
		{
			path: "reporte-encuestas/crear-formulario/:id",
			name: "Crear Formulario",
			component: FormularioCrear,
		},
	],
};

let versionsapp = {
	path: "/versiones",
	redirect: "/versiones/lista",
	name: "Versiones del App",
	meta: { requiresAuth: true },
	component: DashboardLayout,
	children: [
		{
			path: "lista",
			name: "Versiones del App Listado",
			components: { default: VersionesAppList },
		},
		{
			path: "crear",
			name: "Versiones del App Crear",
			components: { default: VersionesAppCreate },
		},
	],
};

let maestros = {
	path: "/maestros",
	redirect: "/maestros/lista",
	name: "Maestros del App",
	meta: { requiresAuth: true },
	component: DashboardLayout,
	children: [
		{
			path: "lista",
			name: "Listado de Maestros",
			components: { default: MaestrosListado },
		},
		{
			path: "editar/:id",
			name: "Editar Maestro",
			components: { default: MaestrosEdit },
		},
		{
			path: "editar-question-cluster/:id",
			name: "Editar Pregunta del Cluster",
			components: { default: MaestroQuestionClusterEdit },
		},
		{
			path: "editar-mercado/:id/:distribuidora",
			name: "Editar Mercado",
			components: { default: MaestroMercadoEdit },
		},
		{
			path: "crear",
			name: "Crear Maestro",
			components: { default: MaestrosCreate },
		},
	],
};

let logsapp = {
	path: "/logs",
	redirect: "/logs/lista",
	name: "Logs del App",
	meta: { requiresAuth: true },
	component: DashboardLayout,
	children: [
		{
			path: "lista",
			name: "Listado de Logs",
			components: { default: LogsList },
		},
	],
};

let incidencias = {
	path: "/incidencias",
	redirect: "/incidencias/listado",
	name: "Incidencias",
	meta: { requiresAuth: true },
	component: DashboardLayout,
	children: [
		{
			path: "lista",
			name: "Listado de Incidencias",
			components: { default: ListadoIncidencias },
		},
		{
			path: "actualizar-estados",
			name: "Actualizar estados de incidencias",
			components: { default: ImportEstadosIncidencias },
		},
	],
};

let catalogoErrores = {
	path: "/catalogo",
	redirect: "/catalogo/lista",
	name: "Catalogo de errores",
	meta: { requiresAuth: true },
	component: DashboardLayout,
	children: [
		{
			path: "lista",
			name: "Listado de Errores",
			components: { default: CatalogoList },
		},
	],
};

const routes = [
	{
		path: "/",
		redirect: "/dashboard",
		component: DashboardLayout,
		name: "Accesos Directos",
		meta: { requiresAuth: true },
		children: [
			{
				path: "/dashboard",
				name: "",
				components: { default: Dashboard },
			},
			{
				path: "/widgets",
				name: "Widgets",
				components: { default: Widgets },
			},
			{
				path: "/charts",
				name: "Charts",
				components: { default: Charts },
			},
			{
				path: "/calendar",
				name: "Calendar",
				components: { default: Calendar },
			},
			{
				path: "/pages/user",
				name: "Profile",
				components: { default: Profile },
			},
			{
				path: "/pages/timeline",
				name: "Timeline",
				components: { default: Timeline },
			},
		],
	},
	{
		path: "/",
		redirect: "/",
		component: AuthLayout,
		children: [
			{
				path: "/pricing",
				name: "Pricing",
				components: { default: Pricing },
			},
			{
				path: "/login",
				name: "Login",
				components: { default: Login },
			},
			{
				path: "/register",
				name: "Register",
				components: { default: Register },
			},
			{
				path: "/lock",
				name: "Lock",
				components: { default: Lock },
			},
		],
	},
	maestroProductos,
	usuariosBackoffice,
	maestroCategorias,
	maestroClientes,
  equiposFriosRouter,
	maestroSupervisores,
	maestroVendedores,
	maestroPedidos,
	maestroNoPedidos,
	maestroPedidosPerdidos,
	maestroCobranzas,
	maestroDepositos,
	maestroHojadeRuta,
	maestroDevoluciones,
	codigosVerificacion,
	Reportes,
	componentsMenu,
	tablesMenu,
	mapsMenu,
	formsMenu,
	parametrosMenu,
	notificaciones,
	notificaciones_v2,
	tiemporeal,
	formulariosyEncuestas,
	versionsapp,
	maestros,
	logsapp,
	incidencias,
	catalogoErrores,
	bonificacionesBackoffice,
	liquidaciones,
];

const router = createRouter({
	history: createWebHistory(),
	linkActiveClass: "active",
	hashbang: false,
	routes,
});

router.beforeEach((to, from, next) => {
	// instead of having to check every route record with
	// to.matched.some(record => record.meta.requiresAuth)
	if (to.matched.some((route) => route.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (store.state.auth.token === null || !store.state.auth.token) {
			next("/login");
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
