import axios from "axios";
import setting from "./setting";

const URL = `${setting.http.base_url}/cliente`;

export default {
	async listByFilters(token, filters) {
		const { data } = await axios.post(`${URL}/lista`, filters, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		});
		return data;
	},
	async uploadComodatoFile(token, codeClient, formData) {
		const { data } = await axios.post(
			`${URL}/subir/${codeClient}/comodato`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			},
		);
		return data;
	},
	async searchByName(token, dex, nameOrCode,placa) {
		const isCode = !isNaN(nameOrCode);
		const params = {
			idDistribuidora: dex,
			codigoCliente: isCode ? nameOrCode : null,
			nombre: !isCode ? nameOrCode : null,
      placa,
		};
		const { data } = await axios.post(`${URL}/lista/desplegable`, params, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		});
		return data;
	},
};
