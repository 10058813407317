/**
 * @abstract
 * @classdesc Base class for handle user inputs
 */
export class Editable {
	edit() {
		throw new Error("Method 'edit' not implemented.");
	}
	get type() {
		throw new Error("Getter Method 'type' not implemented.");
	}
	get value() {
		throw new Error("Getter Method 'vlaue' not implemented.");
	}
}

export class GroupSelectEditable extends Editable {
	constructor(initial = {}) {
		super();
		this.group = initial;
	}
	addSelect(key, val) {
		group[key] = val;
	}
	removeSelect(key) {
		const { [key]: _deleteKey, ...rest } = this.group;
		this.group = rest;
	}
	get value() {
		const obj = {};
		Object.entries(this.group).forEach(([k, v]) => {
			obj[k] = v.selected;
		});
		return obj;
	}
	get type() {
		return "groupSelect";
	}
}

export class InputEditable extends Editable {
	constructor(initialVal) {
		super();
		this.input = initialVal;
	}
	edit(val) {
		this.input = val;
	}
	get value() {
		return this.input;
	}
	get type() {
		return "input";
	}
}

export class SelectEditable extends Editable {
	constructor(options, selected) {
		super();
		this.options = options;
		this.selected = selected;
	}
	edit() {}
	get value() {
		return this.selected;
	}
	get type() {
		return "select";
	}
}
