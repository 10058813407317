<template>
  <div class="row mx-0">
    <div class="col-3 px-0">
      <div class="row mx-0 h-100 d-flex align-items-center">
        <div class="col-3 px-0 text-center font-11 font-weight-bold">
          {{ bonificacion.codigo?? bonificacion.id}}
        </div>
        <div class="col-6 px-0 text-center font-10 font-weight-bold">
          {{ bonificacion.nombre }}
        </div>
        <div class="col-3 px-0 text-center font-11 font-weight-bold">
          {{
            bonificacion.tipo == constantes.TIPO_PROMOCION
              ? "Prom."
              : bonificacion.tipo_label
          }}
        </div>
      </div>
    </div>
    <div class="col-5 px-0">
      <div class="row mx-0 h-100 d-flex align-items-center">
        <div class="col-6 px-1 text-center">
          <ListaRowProduct
            v-for="grupo in bonificacion.prods_promo"
            :key="grupo"
            :grupo="grupo"
          ></ListaRowProduct>
        </div>
        <div class="col-6 px-1 text-center">
          <ListaRowProduct
            v-for="grupo in bonificacion.prods_regalo"
            :key="grupo"
            :grupo="grupo"
            :es_regalo="true"
          ></ListaRowProduct>
        </div>
      </div>
    </div>
    <div class="col-4 px-0">
      <div class="row mx-0 h-100 d-flex align-items-center">
        <div class="col-2 px-1 text-center">
          <b class="font-11">{{ bonificacion.distribuidoras.length }}</b>
        </div>
        <div class="col-3 px-1 text-center">
          <b class="font-11">
            {{  total_clientes }}
          </b>
        </div>
        <div
          class="col-4 px-1 text-center d-flex align-items-center"
          style="min-height: 60px"
        >
          <StackedCards
            :fechas="
              bonificacion.distribuidoras.map((item) => {
                return { inicio: item.inicio, fin: item.fin };
              })
            "
            :show_activas="show_activas"
          ></StackedCards>
        </div>
        <div class="col-3 px-1">
          <div class="row mx-2">
            <button
              class="border-0 bg-white bonificacion-primary-text d-flex align-items-center justify-content-center px-1 mr-1"
              @click.prevent="editarBonificacion"
            >
              <i class="fas fa-edit font-16"></i>
            </button>
            <a
              class="border-0 bg-white bonificacion-primary-text d-flex align-items-center justify-content-center px-1"
              data-toggle="collapse"
              :href="`#${collapse_id}`"
              role="button"
            >
              <i class="fas fa-chevron-circle-down font-16"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="collapse w-100 row mx-0 px-4 mt-3" :id="collapse_id">
    <card
      class="w-100 border shadow-none"
      headerClasses="laive-bg-clean-gray p-2"
      bodyClasses="px-1 py-2"
    >
      <DetailCard :bonificacion="bonificacion"></DetailCard>
    </card>
  </div>
  <hr class="my-1" />
</template>

<script setup>
import StackedCards from "./StackedCards.vue";
import ListaRowProduct from "./ListaRowProduct.vue";
import DetailCard from "./DetailCard.vue";
import { ref, computed, watch, onMounted } from "vue";
import constantes from "@/model/constantes.js";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const { bonificacion, show_activas } = defineProps({
  bonificacion: {
    type: Object,
  },
  show_activas: {
    type: Boolean,
    default: true,
  },
});
const collapse_id = ref(`componente_lista_row_collapse${new Date().getTime()}`);
const total_clientes = computed(() => bonificacion.total_clientes.toLocaleString("en-US"));

const editarBonificacion = ()=>{
  const lista = show_activas ? "activas":"inactivas";
  router.push(`/bonificaciones/editar/${lista}/${bonificacion.relaciones_ids.join(',')}`);
}

</script>

<style lang="scss" scoped>
td {
  text-transform: none;
  font-size: 9px !important;
  font-weight: 600;
  padding: 10px 4px !important;
}
</style>
