export default {
	TIPO_REGALO_PRODUCTO: 'producto',
	TIPO_REGALO_SOLES: 'soles',
	TIPO_REGALO_PORCENTAJE: 'porcentaje',

	TIPO_PROMOCION: 'promocion',
	TIPO_COMBO: 'combo',
	TIPO_ESCALA: 'escala',
	TIPO_REBATE: 'rebate',
	TIPO_ACTV_VENTA: 'actividad de venta',

	PROMOCION_LABEL: 'Promoción',
	COMBO_LABEL: 'Combo',
	ESCALA_LABEL: 'Escala',
	REBATE_LABEL: 'Rebate',

	INDIVIDUAL: 'individual',
	COMBINACION: 'combinacion',

	MATERIAL: 'material',
	DESCUENTO: 'descuento',

	BONIFICACION_ESTADO_POR_APROBAR: 1,
	BONIFICACION_ESTADO_APROBADA: 2,
	BONIFICACION_ESTADO_RECHAZADA: 3,

	RESPONSE_OK: 200,

	POR_REVISAR: 'por revisar',
	PENDIENTE: 'pendiente',
	ENVIADO: 'enviado',
	APROBADO: 'aprobado',
	OBSERVADO: 'observado',
	FINALIZADO: 'finalizado',


};
