<template>
  <div class="th-select" :class="[customClass ? customClass : 'default-select', className]" :style="style"
    v-click-outside="hidden">
    <button class="th-select-option" aria-expanded="true" aria-haspopup="listbox" :disabled="disabled">
      <input type="search" @input="handleSearch" :placeholder="placeholder" :disabled="disabled" :value="modelValue"
        @focus="show" />
      <i class="laive-i icon-search" />
    </button>
    <ul v-if="showOptions" class="th-select-listbox scroller" role="listbox" aria-orientation="vertical" tabindex="0">
      <li v-for="option in options" @click.stop="handleSelect(option.value)">
        {{ option.text }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    customClass: [String, Object],
    className: [String, Object],
    options: {
      type: Array,
      default: []
    },
    style: [Object, String],
    placeholder: String,
    disabled: Boolean,
    modelValue: [String, Number],
  },
  emits: ["update:modelValue", "selectOption"],
  data() {
    return {
      isOpen: true,
    }
  },
  computed: {
    showOptions() {
      return this.isOpen && this.options.length > 0
    }
  },
  methods: {
    handleSelect(value) {
      const opt = this.options.find((opt) => opt.value == value);
      if (opt == undefined) return;
      this.$emit("update:modelValue", opt.text);
      this.$emit("selectOption", opt);
      this.hidden();
    },
    handleSearch(ev) {
      const value = ev.target.value;
      this.$emit("update:modelValue", value);
    },
    show() {
      this.isOpen = true;
    },
    hidden() {
      this.isOpen = false;
    },
  }
}

</script>

<style scoped>
button:disabled {
  background: #F6F6F6;
  cursor: auto;
}

input {
  background-color: inherit;
  flex: 1;
  font-weight: inherit;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  outline: 0;
  padding-inline-start: 0.7em;
  padding-block: 0.3em;
}

i {
  width: 18px !important;
  height: 18px !important;
  margin-inline-end: 0.7em;
  margin-block: 0.3em;
}

/* Default Select   */

.default-select>.th-select-option {
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  padding-inline: 0em;
  padding-block: 0em;
}

.default-select .th-select-option input {
  font-weight: 300;
}

.default-select>.th-select-listbox {
  border: 1px solid #E3E3E3;
  border-radius: 0.25em;
  box-shadow: 2px 5px 4px 0px rgba(0, 0, 0, 0.1);
}

.default-select>.th-select-listbox li {
  padding-inline: 1em;
  padding-block: 0.6em;
  cursor: pointer;
}

.default-select>.th-select-listbox li:not(:last-of-type) {
  border-bottom: 1px solid #E3E3E3;
}

.default-select>.th-select-listbox li:hover {
  background-color: #F1F1F1;
}
</style>
