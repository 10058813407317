<script setup>
import { ref, reactive, watch, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useAppToast } from "@/composables";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb.vue";
import DashboardNavbar from "@/views/Layout/DashboardNavbar.vue";
import { CardTable } from "@/components/Cards";
import CustomModal from "@/views/Components/CustomModal.vue";
import { Distribuidoras, Solicitudes } from "../../services";
import CONFIG from "@/config";
import { debounce } from "../../util";
import { mapSolicitudesInfo } from "./data_mapping";

const toast = useAppToast();
const store = useStore();

const token = computed(() => store.state.auth.token);
const user = computed(() => store.state.auth.user);

const STATUS_COLORS = {
  0: { bg: "#FFEDDC", text: "#FE7B03" },
  1: { bg: "#CAFDE0", text: "#02A54F" },
  3: { bg: "#FEF0F1", text: "#FF3143" },
};
const OFICINAS = {
  ALL: -1,
  LIMA: 1,
  PROV: 2,
};

const loading = ref(false);

const totalDexs = ref([]);

const filters = reactive({
  codeOrName: "",
  oficinaVentas: {
    selected: OFICINAS.ALL,
    options: [
      {
        text: "Todas (Lima y Provincias)",
        value: OFICINAS.ALL,
      },
      {
        text: "Lima",
        value: OFICINAS.LIMA,
      },
      {
        text: "Provincias",
        value: OFICINAS.PROV,
      },
    ],
  },
  dexs: {
    selected: -1,
    options: [{ text: "Todas", value: -1 }],
  },
});

const tabla = reactive({
  headers: [
    { key: "codigoCliente", label: "Cód Cliente" },
    { key: "cliente", label: "Cliente" },
    { key: "segmento", label: "Segmento" },
    { key: "fechaCrea", label: "Fecha" },
    { key: "tipoSolicitud", label: "Tipo de Solicitud" },
    { key: "direccion", label: "Dirección" },
    { key: "horario", label: "Horario" },
    { key: "distribuidora", label: "DEX" },
    { key: "estado", label: "Estado del pedido" },
    { key: "", label: "" },
  ],
  rows: [],
});

const modal = reactive({
  show: false,
  mode: "view",
  loading: false,
  showLinks: false,
  metadata: {},
  links: {
    recibo: "",
    dni: "",
  },
  title: "",
  info: null,
});

onBeforeMount(async () => {
  try {
    if (!token.value) return;

    loading.value = true;

    const dexsData = await Distribuidoras.getAll(token.value);
    const solicitudesData = await Solicitudes.getByFilters(token.value, {
      page: 1,
    });

    const dexs = dexsData
      .filter(({ estado }) => estado == "1")
      .map((dx) => ({
        text:
          dx.razonSocial.charAt(0).toUpperCase() +
          dx.razonSocial.slice(1).toLowerCase(),
        id: dx.idDistribuidora,
        value: dx.idDistribuidora,
      }));

    totalDexs.value = dexs.map((obj) => ({ ...obj }));

    filters.dexs = {
      selected: -1,
      options: [{ text: "Todas", value: -1 }, ...dexs],
    };

    tabla.rows =
      solicitudesData.data?.map((soli) => {
        const fechaCrea = new Date(soli.fechaCrea).toLocaleDateString(
          window.navigator.language,
          {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }
        );

        const horario =
          !!soli.horaInicio && !!soli.horaFin
            ? `${soli.horaInicio} A ${soli.horaFin}`
            : "-";

        return {
          ...soli,
          fechaCrea,
          horario,
        };
      }) ?? [];
  } catch (error) {
    toast.error("No fue posible obtener la informacion");
  } finally {
    loading.value = false;
  }
});

watch(
  () => filters.oficinaVentas.selected,
  (selectedOf) => {
    const selectedDex = filters.dexs.selected;
    let dexs = [...totalDexs.value];
    if (selectedOf == OFICINAS.PROV) {
      dexs = dexs.filter(({ id }) => id > 6);
    } else if (selectedOf === OFICINAS.LIMA) {
      dexs = dexs.filter(({ id }) => id <= 6);
    }
    dexs.push({ text: "Todas", value: -1 });
    dexs.unshift(dexs.pop());
    filters.dexs.options = dexs;
    if (selectedDex !== -1 && dexs.some(({ valor }) => valor === selectedDex)) {
      // la distribuidora seleccionada existe en las distribuidoras filtradas
      // No es necesario cambiar la distribuidora seleccionada
      return;
    }
    filters.dexs.selected = -1;
    fetchData(selectedOf, filters.dexs.selected, filters.codeOrName);
  }
);
watch(
  () => filters.dexs.selected,
  (selected) => {
    fetchData(filters.oficinaVentas.selected, selected, filters.codeOrName);
  }
);

watch(
  () => filters.codeOrName,
  (val) => {
    performSearch(filters.oficinaVentas.selected, filters.dexs.selected, val);
  }
);

const performSearch = debounce(function (oficina, dex, codeOrName) {
  fetchData(oficina, dex, codeOrName);
}, 450);

async function fetchData(oficina, dexSelected, codeOrName) {
  try {
    loading.value = true;

    const isCode = !isNaN(codeOrName);
    const bodySend = { page: 1 };
    if (dexSelected && dexSelected != -1)
      bodySend.idDistribuidora = dexSelected;
    if (oficina)
      bodySend.oficina =
        (oficina == 1) | (oficina == 2) ? String(oficina) : null;

    const data = await Solicitudes.getByFilters(token.value, {
      page: 1,
      ...bodySend,
      codigoCliente: isCode ? codeOrName : null,
      nombreCliente: !isCode ? codeOrName : null,
    });

    tabla.rows = data.data?.map((soli) => {
      const fechaCrea = new Date(soli.fechaCrea).toLocaleDateString(
        window.navigator.language,
        {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      );

      const horario =
        !!soli.horaInicio && !!soli.horaFin
          ? `${soli.horaInicio} A ${soli.horaFin}`
          : "-";

      return {
        ...soli,
        fechaCrea,
        horario,
      };
    });
  } catch (error) {
    toast.error("No fue posible obtener la informacion");
  } finally {
    loading.value = false;
  }
}
function getStatus(st) {
  if (st == "0") return "Pendiente";
  if (st == "1") return "Atendido";
  return "Rechazado";
}
function getStatusColor(st) {
  return STATUS_COLORS[st];
}
function saveData() {
  const mappedData = modal.info.toMapped();
  const updateDetails = {
    ...modal.metadata,
    idSolicitud: modal.metadata.idSolicitud,
    idDistribuidora: modal.metadata.idDistribuidora,
    tipoSolicitud: String(mappedData.tipo),
    direccion: mappedData.direccion,
    personaContacto: mappedData.contacto,
    numeroContacto: mappedData.numero,
    referencia: mappedData.refUbicacion,
    horaInicio: mappedData.horario.inicio,
    horaFin: mappedData.horario.fin,
    estado: mappedData.estado,
    idEquipo: modal.metadata.idEquipo,
  };
  if (mappedData.placa) updateDetails.placa = mappedData.placa;
  if (mappedData.modelo) updateDetails.modelo = mappedData.modelo;
  if (mappedData.acepta)
    updateDetails.aceptaEquipoLaive = mappedData.acepta == "si";
  modal.loading = true;
  Solicitudes.updateDetail(token.value, updateDetails)
    .then((_data) => {
      toast.success("Solicitud actualizada");
    })
    .catch((_e) => {
      toast.error("Error en la actualización");
    })
    .finally(() => {
      closeModal();
      modal.loading = false;
      fetchData(
        filters.oficinaVentas.selected,
        filters.dexs.selected,
        filters.codeOrName
      );
    });
}
function cancelModal() {
  modal.mode = "view";
}
function closeModal() {
  modal.show = false;
  modal.loading = false;
  modal.info = null;
  modal.mode = "view";
  modal.showLinks = true;
  modal.type = "";
  modal.links = { recibo: "", dni: "" };
  modal.title = "";
}

function showModal(i) {
  const sol = tabla.rows.at(i);
  if (!sol || !token.value) return;
  const getTitle = (typ) => {
    if (typ == "Nuevo equipo de frío") return "- Nuevo equipo de frío";
    if (typ == "Recojo de equipo") return "- Recojo";
    return "";
  };
  modal.show = true;
  modal.loading = true;
  modal.type = sol.tipoSolicitud;
  modal.title = `Detalle ${getTitle(sol.tipoSolicitud)}`;
  modal.showLinks = sol.tipoSolicitud == "Nuevo equipo de frío";
  Solicitudes.getDetail(token.value, sol.idSolicitud)
    .then((data) => {
      const info = mapSolicitudesInfo(
        data,
        totalDexs.value.map((obj) => ({ ...obj }))
      );
      modal.info = info;
      modal.links = {
        recibo: data.recibo
          ? CONFIG.storage("/solicitudes/documentos/", data.recibo)
          : "",
        dni: data.dni
          ? CONFIG.storage("/solicitudes/documentos/", data.dni)
          : "",
      };
      modal.metadata = data;
    })
    .catch((_e) => {
      toast.error("Error al obtener la información");
    })
    .finally(() => {
      modal.loading = false;
    });
}

function downloadList() {
  if (!token.value) return;
  loading.value = true;
  Solicitudes.downloadList(token.value)
    .then((d) => {
      console.log({ d });
      toast.success("Se descargó la lista.");
    })
    .catch((_e) => {
      console.log(_e);
      toast.error("Error en la descarga de la lista.");
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<template>
  <header
    aria-label="breadcrumb"
    class="justify-content-between align-items-center d-none d-md-flex"
  >
    <route-bread-crumb></route-bread-crumb>
    <dashboard-navbar></dashboard-navbar>
  </header>
  <div class="card-table-container th-bg-slate py-1 px-3">
    <CardTable
      headerTitle="Solicitudes de activos"
      buttonType="none"
      :loading="loading"
    >
      <template #filters>
        <ui-flex dir="column" gap="0">
          <span class="title_filter">OFICINA DE VENTAS</span>
          <ui-select
            type="dropdown"
            :options="filters.oficinaVentas.options"
            v-model:selected="filters.oficinaVentas.selected"
            class="fw-light fs-xs"
            px="-xs"
            py="-xs"
            bg="white"
            text-color="black"
            hv-bg="-gray-hover"
          />
        </ui-flex>
        <ui-flex dir="column" gap="0">
          <span class="title_filter">DEX</span>
          <ui-select
            type="dropdown"
            :options="filters.dexs.options"
            v-model:selected="filters.dexs.selected"
            class="fw-light fs-xs"
            px="-xs"
            py="-xs"
            bg="white"
            text-color="black"
            hv-bg="-gray-hover"
          />
        </ui-flex>
        <ui-flex dir="column" gap="0">
          <span class="title_filter">Buscar</span>
          <ui-input
            v-model="filters.codeOrName"
            class="fs-xs fw-light"
            px="-xs"
            py="-xxs"
            bg="white"
            text-color="black"
            placeholder="Buscar por código o nombre"
          />
        </ui-flex>
      </template>
      <div class="b-table align-start sticky-header scroller table-h">
        <div class="t-header">
          <div class="t-row">
            <div
              class="t-cell justify-content-start"
              v-for="header in tabla.headers"
              :key="header.label"
              :style="{ minWidth: header.minWidth }"
            >
              <span>{{ header.label }}</span>
            </div>
          </div>
        </div>
        <div v-if="tabla.rows.length > 0" class="t-body text-uppercase">
          <div class="t-row" v-for="(row, i) in tabla.rows">
            <div
              class="t-cell justify-content-start"
              v-for="{ key } in tabla.headers.slice(0, -2)"
            >
              {{ row[key] }}
            </div>
            <div class="t-cell justify-content-start">
              <ui-badge
                type="chip"
                :text="getStatus(row['estado'])"
                :bg="getStatusColor(row['estado']).bg"
                :text-color="getStatusColor(row['estado']).text"
                class="text-uppercase text-center"
                fw="600"
                px="-base"
                py="0.4em"
                fs="-xxs"
                w="98%"
              />
            </div>
            <div class="t-cell justify-content-start">
              <button
                class="th-link-pry see-details-btn text-center"
                role="button"
                @click="showModal(i)"
                style="--style: none"
              >
                Ver detalle
              </button>
            </div>
          </div>
        </div>
        <div v-if="tabla.rows.length == 0" class="t-nt-found">
          <img src="/img/shared/search.svg" alt="No encontrado" />
          <p class="text">No hay datos que mostrar</p>
        </div>
      </div>
    </CardTable>
    <ui-flex class="mt-3 d-flex" justify="end">
      <button
        v-if="
          user.rol === 1 || user.rol === 2 || user.rol === 3 || user.rol === 4
        "
        class="app-btn-pry-icon"
        :disabled="loading"
        @click="downloadList"
      >
        Descargar lista
        <i class="laive-i icon-download-white" />
      </button>
    </ui-flex>
  </div>
  <CustomModal
    :show="modal.show"
    :isHeader="true"
    width="540"
    contentClass="p-4"
    @modal:close="closeModal"
    showCloseIcon
  >
    <template #header>
      <ui-title
        fs="-base"
        as="h3"
        text-color="-brand"
        fw="-smBold"
        class="text-uppercase"
      >
        {{ modal.title }}
      </ui-title>
    </template>
    <template #body>
      <box-loader :loading="modal.loading" minHeight="130px" hiddenInLoading>
        <ui-flex dir="column" gap="-xs" class="mb-2">
          <ui-title as="h3" text-color="black" fs="-base" fw="-smBold">
            Datos Generales
          </ui-title>
          <ui-grid
            columns="repeat(auto-fill,minmax(140px,1fr))"
            v-if="modal.info"
            as="div"
            gap="-md"
            class="info-detail"
            :class="{ detail: modal.type == 'Reportar' }"
          >
            <ui-flex
              dir="column"
              justify="start"
              gap="0.3em"
              v-for="info in modal.info.editables"
            >
              <ui-title as="h4" fs="-xs" fw="-medium" text-color="-black">
                {{ info.label }}
              </ui-title>
              <span
                class="fw-light fs-xs text-dark"
                v-if="modal.mode == 'view' || !info.isEditable"
              >
                {{ info.view }}
              </span>
              <template v-else>
                <ui-flex
                  dir="column"
                  gap="0.2em"
                  v-if="info.edit.type == 'groupSelect'"
                >
                  <ui-flex dir="row" gap="0.4em" align="center">
                    <span class="fs-xs fw-light text-dark">Desde:</span>
                    <ui-select
                      v-model:selected="info.edit.group.inicio.selected"
                      class="fs-xs fw-light"
                      px="0.4rem"
                      py="0.2rem"
                      bg="white"
                      hv-bg="-gray-hover"
                      text-color="black"
                      :options="info.edit.group.inicio.options"
                      view="input"
                      :style="{ minWidth: '80px', minHeight: '20px' }"
                    />
                  </ui-flex>
                  <ui-flex dir="row" gap="0.4em" align="center">
                    <span class="fs-xs fw-light text-dark">Hasta:</span>
                    <ui-select
                      v-model:selected="info.edit.group.fin.selected"
                      class="fs-xs fw-light"
                      px="0.4rem"
                      py="0.2rem"
                      bg="white"
                      hv-bg="-gray-hover"
                      text-color="black"
                      :options="info.edit.group.fin.options"
                      view="input"
                      :style="{ minWidth: '80px', minHeight: '20px' }"
                    />
                  </ui-flex>
                </ui-flex>
                <ui-select
                  type="dropdown"
                  :options="info.edit.options"
                  v-model:selected="info.edit.selected"
                  class="fs-xs fw-light text-dark"
                  px="-xs"
                  py="-xxs"
                  bg="white"
                  hv-bg="-gray-hover"
                  v-if="info.edit.type == 'select' && modal.mode == 'edit'"
                />
                <ui-input
                  v-model="info.edit.input"
                  fs="-xs"
                  fw="-light"
                  px="0.4em"
                  py="0.3em"
                  v-else-if="info.edit.type == 'input' && modal.mode == 'edit'"
                  :show-input="false"
                  spellcheck="false"
                />
              </template>
            </ui-flex>
          </ui-grid>
        </ui-flex>
        <ui-flex
          dir="column"
          align="start"
          gap="0.5em"
          class="mt-3"
          v-if="modal.showLinks"
        >
          <a
            class="th-link-pry fw-semibold fs-xs th-flex-r"
            target="_blank"
            style="--gap: 0.4em"
            :href="modal.links.recibo"
            v-if="modal.links.recibo"
          >
            <i class="laive-i icon-attach-green" />
            Ver Recibo adjunto
          </a>
          <span class="fs-xs fw-light text-dark" v-else
            >No hay recibo adjunto</span
          >
          <a
            class="th-link-pry fw-semibold fs-xs th-flex-r"
            target="_blank"
            style="--gap: 0.4em"
            :href="modal.links.dni"
            v-if="modal.links.dni"
          >
            <i class="laive-i icon-attach-green" />
            Ver DNI adjunto
          </a>
          <span class="fs-xs fw-light text-dark" v-else
            >No hay DNI adjunto</span
          >
        </ui-flex>
        <ui-split
          v-if="user.rol === 1 || user.rol === 2"
          color="-gray"
          dir="x"
          class="my-3"
        />
        <ui-flex
          v-if="user.rol === 1 || user.rol === 2"
          dir="row"
          justify="end"
          class="mt-2"
        >
          <th-btn
            type="outline"
            fs="-sm"
            v-if="modal.mode == 'view'"
            @click="modal.mode = 'edit'"
            border-w="2"
            fw="-smBold"
            px="2.5em"
          >
            Editar
          </th-btn>
          <template v-else>
            <th-btn
              type="outline"
              border-w="2"
              fw="-smBold"
              fs="-sm"
              @click="cancelModal"
            >
              Cancelar
            </th-btn>
            <th-btn
              type="solid"
              fs="-sm"
              fw="-smBold"
              px="1.8em"
              @click="saveData"
            >
              Guardar
            </th-btn>
          </template>
        </ui-flex>
      </box-loader>
    </template>
  </CustomModal>
</template>

<style scoped>
header {
  padding-inline: 1em;
  padding-block: 1.2em;
  padding-bottom: 4.5em;
}

header :deep(nav:last-child) {
  padding: 0 !important;
}

.card-table-container :deep(.card-table) {
  margin-top: -3.5em;
}

@media screen and (max-width: 760px) {
  .card-table-container {
    margin-top: 4.5em;
  }
}

:deep(.card-table-filters .ui-input) {
  flex: 1;
}

.detail > *:nth-last-child(2) {
  justify-content: space-between;
}

.info-detail :is(.ui-select-btn, .ui-input) {
  background-color: white;
  padding-block: 0.3em !important;
  padding-inline: 0.4em !important;
  font-size: 0.75rem;
  color: black !important;
}

.card-table-container :deep(.card-table) {
  margin-top: -3.5em;
}

.card-table-container :deep(.t-body) {
  font-weight: 400;
}

:deep(.card-table-filters .ui-select) {
  flex: 1;
}
.card-table-container :deep(.b-table) {
  --columns: 1.4fr 1.4fr 1fr 1fr 1.4fr 2fr 1.4fr 1fr 1.3fr 1.2fr;
  --columns-sm: 1.2fr 1.4fr 1.2fr 1.2fr 1.2fr 2fr 1fr 1fr 1.3fr 1.2fr;
}
</style>
