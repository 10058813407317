<template>
  <div
    class="row mx-0"
    v-if="
      [
        constantes.TIPO_PROMOCION,
        constantes.TIPO_COMBO,
        constantes.TIPO_REBATE,
      ].includes(bonificacion.tipo)
    "
  >
    <div
      class="col-6 border-right"
      v-if="bonificacion.tipo == constantes.TIPO_REBATE"
    >
      <b> Categorías </b>
      <div
        class="row mx-0"
        v-for="grupo in bonificacion.prods_promo"
        :key="grupo"
      >
        {{ categorias }}
      </div>
    </div>
    <div class="col-6 border-right" v-else>
      <b> Productos </b>
      <div
        class="row mx-0"
        v-for="grupo in bonificacion.prods_promo"
        :key="grupo"
      >
        <ProductDetail :grupo="grupo"></ProductDetail>
      </div>
    </div>

    <div class="col-6">
      <b>Bonificación </b>
      <div
        class="w-100"
        v-for="grupo in bonificacion.prods_regalo"
        :key="grupo"
      >
        <div
          class="row mx-0"
          v-if="grupo.tipo == constantes.TIPO_REGALO_PRODUCTO"
        >
          <ProductDetail :grupo="grupo"></ProductDetail>
        </div>
        <div class="row mx-0" v-else>
          <ValueDetail
            :grupo="grupo"
            :tipo="bonificacion.tipo_label"
            :costo="bonificacion.costo_producto_total"
          ></ValueDetail>
        </div>
      </div>
    </div>
  </div>
  <div class="row mx-0" v-if="bonificacion.tipo == constantes.TIPO_ESCALA">
    <div class="col-12">
      <b>Escalas</b>
      <div class="row mx-0">
        <div class="col-12">
          <EscalaTable
            :bonificacion="bonificacion"
            :can_edit="false"
          ></EscalaTable>
        </div>
      </div>
    </div>
  </div>
  <div class="row mx-0">
    <div class="col-12 d-flex w-100 mt-3" v-if="multiples_fechas_fin">
      <b class="m-0 py-1 my-1 mr-3 min-width-fit-content"> Fecha Fin </b>
      <SummaryDates :bonificacion="bonificacion"> </SummaryDates>
    </div>
    <div class="col-12 d-flex w-100 mt-3">
      <b class="m-0 py-1 my-1 mr-3 min-width-fit-content"> Resumen </b>
      <Summary :bonificacion="bonificacion"> </Summary>
    </div>
    <div class="col-12 d-flex w-100 mt-3" v-if="bonificacion.excel_path">
      <b class="m-0 py-1 my-1 mr-3"> Excel </b>
      <a
        :href="`${base_path}/${bonificacion.excel_path}`"
        :download="bonificacion.excel_name"
      >
        <Chip
          aditional_class="chip-primary bonificacion-primary-text"
          :texto="bonificacion.excel_name"
        ></Chip>
      </a>
    </div>
    <div class="col-12 mt-3">
      <div class="row mx-0 w-100 d-flex align-items-center">
        <b> Total clientes </b> <Chip :texto="total_clientes"></Chip>
      </div>
    </div>
  </div>
</template>

<script setup>
import constantes from "../../../model/constantes";
import Chip from "./Chip.vue";
import ProductDetail from "./ProductDetail.vue";
import ValueDetail from "./ValueDetail.vue";
import Summary from "./Summary.vue";
import SummaryDates from "./SummaryDates.vue";
import EscalaTable from "./EscalaTable.vue";
import { useStore } from "vuex";
const store = useStore();

import { ref, computed, watch, onMounted } from "vue";

const { bonificacion } = defineProps({
  bonificacion: {
    type: Object,
  },
});

const base_path = computed(() => {
  return process.env.VUE_APP_STORAGE_TRADE;
});
const categorias = computed(() => {
  if (bonificacion.tipo == constantes.TIPO_REBATE) {
    const grupo = bonificacion.prods_promo[0];
    const result = store.state.bonificacionUtil.categorias
      .filter((item) => grupo.categorias.includes(item.codigodato))
      .map((item) => item.nombre);
    return result.join(", ");
  }
  return null;
});

const multiples_fechas_fin = computed(() => {
  const is_object = typeof bonificacion.distribuidoras[0] == "object";
  if (is_object) {
    const unicas = bonificacion.distribuidoras
      .map((item) => item.fin)
      .filter((valor, indice, self) => {
        return self.indexOf(valor) === indice;
      });
    return unicas.length > 1;
  }
});
const total_clientes = computed(() =>
  bonificacion.total_clientes.toLocaleString("en-US")
);
</script>
