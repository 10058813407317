import axios from "axios";
import setting from "./setting";

const ENDPOINT_URL = `${setting.http.base_url}/parametro`;

export default {
	async getIndicador(token, codigo) {
		const { data } = await axios.get(`${ENDPOINT_URL}/lista/${codigo}`, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		});
		return data;
	},
	async updateIndicador(token, id, valor) {
		const { data } = await axios.put(
			`${ENDPOINT_URL}/actualizar`,
			{
				idParametro: id,
				valor: valor,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			},
		);
		return data;
	},
};
