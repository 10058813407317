<template>
  <div class="content bonificacion">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
      <div class="row mx-0 my-2">
        <div
          v-for="mensaje in mensajes"
          :key="mensaje"
          class="col-12 border chip chip-message py-1"
          :class="{
            [mensaje.clase]: mensaje.clase,
          }"
          :style="`opacity: ${mensaje.opacidad};`"
        >
          <i class="far fa-bell"></i>
          <span class="pl-2"> {{ mensaje.texto }} </span>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div :class="{ validateClient: !all_loaded }" class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card bg-white"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          headerClasses="border-0 px-0"
        >
          <template v-slot:header>
            <div class="row p-0 mx-0">
              <div class="col-12 col-md-3 d-flex align-items-center px-3">
                <span class="mb-0 title"><b>Liquidaciones</b></span>
              </div>
              <div class="col-12 col-md-9 text-right">
                <div class="row mx-0 px-0">
                  <base-button
                    size="sm"
                    type="info"
                    class="ml-auto submit-btns w-auto px-2 py-1"
                    @click.prevent="confirmarMemo"
                  >
                    <span class="font-11">Enviar Memo Aprobación</span>
                  </base-button>
                </div>
              </div>
            </div>
          </template>
          <div class="row mx-0 px-3">
            <div class="col-5 px-0">
              <div class="row mx-0">
                <div class="col-4 px-1">
                  <b class="font-11">Dex</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.dex"
                        aditional_class="bg-white mh-35px"
                        :options="distribuidoras_options"
                        :is_multiple="true"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
                <div class="col-4 px-1">
                  <b class="font-11">Estado</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.estado"
                        aditional_class="bg-white mh-35px"
                        :options="estados_options"
                        :is_multiple="true"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
                <div class="col-4 px-1">
                  <b class="font-11">Mes a visualizar</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.mes"
                        aditional_class="bg-white mh-35px"
                        :options="meses_options"
                        :is_multiple="false"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7 px-0">
              <div class="row mx-0 h-100">
                <div class="col-2 px-1 text-center">
                  <div class="row mx-0 h-100">
                    <div class="col-12 px-0">
                      <b class="font-11">Total del mes</b>
                    </div>
                    <div class="col-12 px-0 bonificacion-primary-text">
                      <b> S/ {{costo_total}} </b>
                    </div>
                  </div>
                </div>
                <div class="col-2 px-1 text-center">
                  <div class="row mx-0 h-100">
                    <div class="col-12 px-0">
                      <b class="font-11">Ratio venta</b>
                    </div>
                    <div class="col-12 px-0 bonificacion-primary-text">
                      <b> 75% </b>
                    </div>
                  </div>
                </div>

                <div class="col-2 px-1 text-center">
                  <div class="row mx-0 h-100">
                    <div class="col-12 px-0">
                      <b class="font-11">Liquidados</b>
                    </div>
                    <div
                      class="col-12 px-0"
                      :class="{
                        'text-danger':
                          distribuidoras_completas <
                          liquidaciones_por_distribuidoras_raw.length,
                        'bonificacion-primary-text':
                          distribuidoras_completas ==
                          liquidaciones_por_distribuidoras_raw.length,
                      }"
                    >
                      <b>
                        {{ distribuidoras_completas }}/{{
                          liquidaciones_por_distribuidoras_raw.length
                        }}
                      </b>
                    </div>
                  </div>
                </div>

                <div class="col-2 px-1 border-left text-center">
                  <div class="row mx-0 h-100">
                    <div class="col-12 px-0">
                      <b class="font-11">Octubre</b>
                    </div>
                    <div class="col-12 px-0">
                      <span> S/ 39,000 </span>
                    </div>
                  </div>
                </div>

                <div class="col-2 px-1 text-center">
                  <div class="row mx-0 h-100">
                    <div class="col-12 px-0">
                      <b class="font-11">Noviembre</b>
                    </div>
                    <div class="col-12 px-0">
                      <span> S/ 39,500 </span>
                    </div>
                  </div>
                </div>
                <div class="col-2 px-1 text-center">
                  <div class="row mx-0 h-100">
                    <div class="col-12 px-0">
                      <b class="font-11">Diciembre</b>
                    </div>
                    <div class="col-12 px-0">
                      <span> S/ 39,000 </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-0 mt-2 header">
            <div class="col-6 px-0">
              <div class="row mx-0">
                <div class="col-4 px-0">
                  <div class="row mx-0 h-100">
                    <div
                      class="col-4 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                    >
                      Cód.
                    </div>
                    <div
                      class="col-8 px-1 font-10 py-2 text-center d-flex align-items-center"
                    >
                      Dex
                    </div>
                  </div>
                </div>
                <div class="col-8 px-0">
                  <div class="row mx-0 h-100">
                    <div
                      class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                    >
                      Cant. Bonificable
                    </div>
                    <div
                      class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                    >
                      Cant. Bonificada
                    </div>
                    <div
                      class="col-2 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                    >
                      Efica
                    </div>
                    <div
                      class="col-2 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                    >
                      Por revisar
                    </div>
                    <div
                      class="col-2 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                    >
                      Costo total S/
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 px-0">
              <div class="row mx-0 h-100">
                <div class="col-2 px-0">
                  <div class="row mx-0 h-100">
                    <div
                      class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                    >
                      Aprobadas
                    </div>
                  </div>
                </div>
                <div class="col-2 px-0">
                  <div class="row mx-0 h-100">
                    <div
                      class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                    >
                      Nro. memo
                    </div>
                  </div>
                </div>
                <div class="col-6 px-0">
                  <div class="row mx-0 h-100">
                    <div
                      class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                    ></div>
                  </div>
                </div>
                <div class="col-2 px-0">
                  <div class="row mx-0 h-100">
                    <div
                      class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Row
            v-if="data_cargada"
            v-for="liquidaciones_por_distribuidora in liquidaciones_por_distribuidoras"
            :key="liquidaciones_por_distribuidora"
            :distribuidora="liquidaciones_por_distribuidora"
          ></Row>
        </card>
      </div>
    </div>
  </div>
</template>

<script setup>
import "@/assets/sass/laive/bonificaciones.scss";
import DashboardNavbar from "../../Layout/DashboardNavbar";
// import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

import { ref, computed, watch, onMounted } from "vue";
import GeneralDropdown from "../../Bonificaciones/components/GeneralDropdown.vue";
import Row from "./components/Row.vue";
import { useStore } from "vuex";
import constantes from "@/model/constantes.js";
const store = useStore();
import Swal from "sweetalert2";

const valores_completos = [constantes.APROBADO, constantes.FINALIZADO];

const distribuidoras_completas = computed(() => {
  const completas = liquidaciones_por_distribuidoras_raw.value.filter(
    (distribuidora) =>
      distribuidora.liquidaciones.every((liquidacion) =>
        valores_completos.includes(liquidacion.estado)
      )
  );
  return completas.length;
});

const costo_total = computed(() => {
  let total = 0;
  liquidaciones_por_distribuidoras_raw.value.forEach((distribuidora) => {
    const result = distribuidora.liquidaciones.reduce(
      (acumulador, objeto) =>
        acumulador +
        parseFloat(objeto.costo_total_ajustado ?? objeto.costo_total ?? 0),
      0
    );
    total += result;
  });
  return formatearCantidades(total);
});


const formatearCantidades = (param) => {
  const cantidad = parseFloat(param);
  return cantidad.toLocaleString("en-US");
};


const liquidaciones_por_distribuidoras_raw = computed(
  () => store.state.liquidacion.liquidaciones_subgerente
);

const liquidaciones_por_distribuidoras = computed(() => {
  data_cargada.value = false;
  const result = liquidaciones_por_distribuidoras_raw.value;
  const filtrado = result.filter((distribuidora) =>
    distribuidora.liquidaciones.some((liquidacion) =>
      filtro.value.estado.includes(liquidacion.estado)
    )
  );
  data_cargada.value = true;
  return filtrado;
});

const all_loaded = computed(
  () => store.state.bonificacionUtil.distribuidoras?.length > 0
);
const filtro = ref({});
const listas_cargadas = ref(false);
const data_cargada = ref(false);

onMounted(() => {
  store
    .dispatch("bonificacionUtil/cargarLista", "distribuidoras")
    .then((result) => {
      setTimeout(() => {
        filtro.value.dex = distribuidoras_options.value.map(
          (item) => item.value
        );
        const fechaActual = new Date();
        filtro.value.mes = fechaActual.getMonth() + 1;
        filtro.value.year = fechaActual.getFullYear();
        filtro.value.estado = [
          constantes.POR_REVISAR,
          constantes.ENVIADO,
          constantes.APROBADO,
          constantes.OBSERVADO,
          constantes.FINALIZADO,
        ];
        const request = {
          year: filtro.value.year,
          month: filtro.value.mes,
          distribuidoras: filtro.value.dex.join(","),
        };
        store
          .dispatch("liquidacion/obtenerLiquidacionesSubGerente", request)
          .then(() => {
            data_cargada.value = true;
          });
        listas_cargadas.value = true;
      }, 500);
    });
});

const distribuidoras_options = computed(() =>
  store.state.bonificacionUtil.distribuidoras.map((item) => {
    return {
      value: item.iddistribuidora,
      label: item.codigodistribuidora,
    };
  })
);
const meses_options = computed(() => [
  {
    value: 1,
    label: "Enero",
  },
  {
    value: 2,
    label: "Febrero",
  },
  {
    value: 3,
    label: "Marzo",
  },
  {
    value: 4,
    label: "Abril",
  },
  {
    value: 5,
    label: "Mayo",
  },
  {
    value: 6,
    label: "Junio",
  },
  {
    value: 7,
    label: "Julio",
  },
  {
    value: 8,
    label: "Agosto",
  },
  {
    value: 9,
    label: "Setiembre",
  },
  {
    value: 10,
    label: "Octubre",
  },
  {
    value: 11,
    label: "Noviembre",
  },
  {
    value: 12,
    label: "Diciembre",
  },
]);
const estados_options = computed(() => [
  {
    value: constantes.POR_REVISAR,
    label: "Por revisar",
  },
  {
    value: constantes.ENVIADO,
    label: "Enviado",
  },
  {
    value: constantes.APROBADO,
    label: "Aprobado",
  },
  {
    value: constantes.OBSERVADO,
    label: "observado",
  },
  {
    value: constantes.FINALIZADO,
    label: "Finalizado",
  },
]);

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

const confirmarMemo = () => {
  swalWithBootstrapButtons
    .fire({
      title: "Enviar Memo de Aprobación",
      text:
        "Esta acción es irreversible, sólo se enviarán las liquidaciones aprobadas tanto por el Jefe de Operaciones como por el Subgerente",
      showCancelButton: true,
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        store
          .dispatch("liquidacion/CrearMemo", {
            year: filtro.value.year,
            mes: filtro.value.mes,
          })
          .then((respuesta) => {
            store.dispatch("liquidacion/obtenerLiquidacionesSubGerente", {
              year: filtro.value.year,
              month: filtro.value.mes,
              distribuidoras: filtro.value.dex.join(","),
            });
          });
      }
    });
};
const obtenerLiquidacionesAprobadas = () => {
  return liquidaciones_por_distribuidoras.value.reduce(
    (acumulador, distribuidora) => {
      return acumulador.concat(
        distribuidora.liquidaciones.filter(
          (item) => item.estado == constantes.APROBADO
        )
      );
    },
    []
  );
};

const aplicarFiltros = () => {
  if (data_cargada.value == true) {
    data_cargada.value = false;
    const request = {
      year: filtro.value.year,
      month: filtro.value.mes,
      distribuidoras: filtro.value.dex.join(","),
    };

    store
      .dispatch("liquidacion/obtenerLiquidacionesSubGerente", request)
      .then(() => {
        data_cargada.value = true;
      });
  }
};

watch(
  () => filtro.value.mes,
  (nuevoValor, valorAnterior) => {
    aplicarFiltros();
  }
);

watch(
  () => filtro.value.dex,
  (nuevoValor, valorAnterior) => {
    aplicarFiltros();
  }
);

const mensajes = computed(() => store.state.liquidacion.mensajes);
</script>

<style scoped>
.submit-btns {
  height: fit-content !important;
}
</style>
