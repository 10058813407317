<template>
  <div>
    <!-- Header -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0 form_laive validateClient">
            <div class="card-header bg-transparent">
              <div class="text-muted text-center mt-2">
                <h1 class="titleLive">¡Bienvenido!</h1>
                <p class="text_general">Ingresa con tus credenciales</p>
              </div>
            </div>
            <div class="card-body px-lg-5 pb-lg-4 pt-lg-3 form_login">
              <!-- <pre>{{model}}</pre> -->
              <Form @submit="submitLogin" :validation-schema="schema" v-slot="{ errors }">
                <div class="box_loader">
                  <div class="loader"></div>
                </div>
                <div class="form-group">
                  <span class="title_filter">Rol:</span>
                  <Field name="rol" as="select" class="form-control" :class="{ 'is-invalid': errors.rol }" @change="changeRol">
                    <option value="">Seleccione un Rol</option>
                    <option
                      v-for="item in roles"
                      :value="item.id"
                      :key="item.id"
                    >
                      {{ item.rol }}
                    </option>
                  </Field>
                  <div class="invalid-feedback">{{errors.rol}}</div>
                </div>
                <template v-if="model.rol == 4">
                  <div class="form-group">
                    <span class="title_filter">Distribuidoras:</span>
                    <Field name="distribuidora" as="select" class="form-control" :class="{ 'is-invalid': errors.distribuidora }">
                      <option value="">Seleccione una Distribuidora</option>
                      <option
                        v-for="item in distribuidoras"
                        :value="item.id"
                        :key="item.id"
                      >
                        {{ item.razonSocial }}
                      </option>
                    </Field>
                    <div class="invalid-feedback">{{errors.distribuidora}}</div>
                  </div>
                </template>
                <base-input
                  alternative
                  name="user"
                  addon-left-icon="ni ni-single-02"
                  placeholder="Usuario"
                  @keypress="handleKeyPress"
                  v-model="model.user"
                >
                </base-input>

                <base-input
                  alternative
                  name="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  type="password"
                  placeholder="Password"
                  v-model="model.password"
                >
                </base-input>
                <template v-if="model.messageLogin">
                  <div class="text-danger invalid-feedback mt-2" style="display: block;">{{model.messageLogin}}</div>
                </template>
                <div class="text-center">
                  <base-button type="primary" native-type="submit" class="btn-success my-4"
                    >Iniciar Sesión
                  </base-button>
                </div>
              </Form>
            </div>
          </div>
          <!-- <div class="row mt-3">
            <div class="col-6">
              <router-link to="/dashboard" class="text-light"
                ><small>Forgot password?</small></router-link
              >
            </div>
            <div class="col-6 text-right">
              <router-link to="/register" class="text-light"
                ><small>Create new account</small></router-link
              >
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { markRaw } from "vue";
  import { Form, Field } from "vee-validate";
  import {mapActions, mapGetters} from 'vuex';
  import * as Yup from "yup";
  import axios from "axios";
  Yup.setLocale({
    mixed: {
      required: 'Este campo es requerido'
    },
    string: {
      email: 'Preencha um e-mail válido',
      min: 'Valor muito curto (mínimo ${min} caracteres)',
      max: 'Valor muito longo (máximo ${max} caracteres)'
    },
    number: {
      min: 'Valor inválido (deve ser maior ou igual a ${min})',
      max: 'Valor inválido (deve ser menor ou igual a ${max})'
    }
  });
  export default {
    components: {
      Form,
      Field,
    },
    computed:{
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
      })
    },
    mounted(){

    },
    methods: {
      ...mapActions({
        signIn: 'auth/signIn',

      }),
      handleKeyPress(e) {
        // console.log('blur', e.target.placeholder)
        let messageApi = this.model.messageLogin;
        // console.log(messageApi.length);
        if(messageApi.length){
          this.model.messageLogin = "";
        }
      },
      changeRol(evn) {
        let selected = Number(evn.target.value)
        this.model.rol = selected
      },
      submitLogin() {
        const data = {
          codigo        : this.model.user,
          pass          : this.model.password,
          rol           : document.querySelector("select[name='rol']").value,
        };
        if(Number(document.querySelector("select[name='rol']").value) == 4){
          let idDistribuidora = Number(document.querySelector("select[name='distribuidora']").value)
          data.distribuidora = idDistribuidora
        }
        console.log(data)
        document.querySelector(".form_laive").classList.add("validateClient")
        this.signIn(data).then((response) => {
          document.querySelector(".form_laive").classList.remove("validateClient")
          if (response.status == 200) {
            this.$router.replace({
              name: 'Accesos Directos',
            })
          }else if(response.status == 400){
            this.model.messageLogin = response.message
          }
          console.log({response});
        })
      },
    },
    created(){
      let $this = this
      if (this.authenticated) {
        this.$router.replace({
          name: 'Accesos Directos',
        })
      }
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      axios.post(process.env.VUE_APP_API_URL+"/usuarios/parametros","",config)
      .then(function (response){
        const data = response.data
        $this.roles = data['roles']
        $this.distribuidoras = data['distribuidoras']
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
      let testEnv = process.env
      console.log({testEnv})
    },
    data() {
      const schema = markRaw(
        Yup.object().shape({
          user: Yup.string().required(),
          password: Yup.string().required(),
          rol: Yup.string().required(),
          distribuidora: Yup.string().required(),
        })
      );
      return {
        schema,
        distribuidoras:[],
        roles: [],
        model: {
          user: "",
          password: "",
          rol: "",
          distribuidora: "",
          islogged : this.$store.state.auth.isLogged,
          messageLogin: ''
        },
      };
    }
  };
</script>
