import axios from "axios";
import setting from "./setting";
import { downloadFile } from "../util";
import moment from "moment";

const ENDPOINT_URL = `${setting.http.base_url}/Equipos`;

export default {
  async getByFilters(token, dataPost = { page: 1 }) {
    const { data } = await axios.post(`${ENDPOINT_URL}/lista`, dataPost, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  },
  async getDetails(token, equipoId) {
    const { data } = await axios.get(`${ENDPOINT_URL}/detalle/${equipoId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  },
  async updateDetails(token, equipoData) {
    const { data } = await axios.put(`${ENDPOINT_URL}/editar/`, equipoData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  },
  async loadDataByFile(token, formData) {
    const { data } = await axios.post(`${ENDPOINT_URL}/cargar`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  },
  async downloadSingleQr(token, id) {
    const { data } = await axios.post(
      `${ENDPOINT_URL}/equipo-qr/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (data.status != 200) {
      throw new Error("El pedido no fue existoso");
    }
    await downloadFile(
      `${new URL(setting.http.base_url).origin}/${data.message}`,
      data.message
    );
    return data;
  },
  async downloadListQr(token, { codigoOficinaVenta, idDistribuidora, placa }) {
    const { data } = await axios.post(
      `${ENDPOINT_URL}/exportar-qr`,
      { codigoOficinaVenta, idDistribuidora, placa },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      }
    );
    const href = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = href;
    link.download = `equipos_qr_${moment().format("HHmmssSSS")}.pdf`;
    document.body.appendChild(link);
    link.click();

    // limpiar
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  },
  async downloadList(token, dataPost) {
    if (dataPost == undefined) {
      throw new Error("DataPost debe ser un objeto válido");
    }
    const { data } = await axios.post(`${ENDPOINT_URL}/exportar`, dataPost, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    await downloadFile(
      `${new URL(setting.http.base_url).origin}/${data.message}`,
      data.message
    );
    return data;
  },
};
