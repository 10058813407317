<script setup>
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { useAppToast } from "@/composables";
import { Parametros } from "@/services";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DashboardNavbar from "@/views/Layout/DashboardNavbar";

const toast = useAppToast();
const store = useStore();
const token = computed(() => store.state.auth.token);
const parametro = ref(null);

const loading = ref(false);

const number = ref(28);
function setNumber(ord = "menos") {
  if (ord == "menos") {
    number.value = number.value - 1 <= 0 ? 0 : number.value - 1;
  } else if (ord == "mas") {
    number.value = number.value + 1 > 28 ? 28 : number.value + 1;
  }
}

onBeforeMount(() => {
  loading.value = true;
  Parametros.getIndicador(token.value, "notificacion_envio_inventario")
    .then((res) => {
      const data = res[0];
      const n = Number(data.valor);
      parametro.value = {
        id: data.idParametro,
        valor: Number(n),
      };
      number.value = n;
    })
    .catch((_e) => {
      toast.error("No fue posible obtener la informacion");
    })
    .finally(() => {
      loading.value = false;
    });
});
const isChanged = computed(
  () => Number(parametro.value?.valor) !== number.value,
);

function restore() {
  number.value = parametro.value?.valor;
}

function aplicar() {
  if (!isChanged.value || parametro.value == null) return;
  loading.value = true;
  Parametros.updateIndicador(
    token.value,
    parametro.value.id,
    String(number.value),
  )
    .then((res) => {
      if (res.status !== 200) return;
      parametro.value.valor = Number(number.value);
      toast.success("Parámetro actualizado con éxito");
    })
    .catch((_e) => {
      toast.error("Ocurrió un error en la actualización.");
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<template>
  <header
    aria-label="breadcrumb"
    class="justify-content-between align-items-center d-none d-md-flex"
  >
    <route-bread-crumb></route-bread-crumb>
    <dashboard-navbar></dashboard-navbar>
  </header>
  <section class="p-4 th-bg-slate text-dark">
    <h1 class="fs-2xl fw-bold border-b border-gray text-dark">
      Parámetros de equipos de frío
    </h1>
    <hr class="my-3" />
    <h4 class="fs-base mt-3 text-dark" :style="{ fontWeight: 500 }">
      Esto aplica a todos los
      <b class="fw-semibold th-text-primary">vendedores.</b>
    </h4>
    <box-loader :loading="loading">
      <div
        class="bg-white py-5 px-5 row-middle rounded-lg mt-4 gap-4 justify-between"
        v-if="parametro"
      >
        <div>
          <h4 class="fw-semibold fs-lg mb-2 text-dark">Notificaciones</h4>
          <span class="fs-sm box-desc block"
            >Ingresa el tiempo límite para notificación del envío de inventario.
          </span>
        </div>
        <div>
          <span class="th-text-gray fs-xs fw-medium"> Días totales </span>
          <div class="days-box gap-1">
            <span class="px-1 py-1 fw-bold fs-2xl th-text-gray">
              {{ number }}
            </span>
            <div class="flex flex-col flex-1 gap-1">
              <button @click="setNumber('mas')">+</button>
              <button @click="setNumber('menos')">-</button>
            </div>
          </div>
        </div>
        <div class="row-middle gap-3 btn-controls fs-sm fw-semibold">
          <button
            class="app-btn-outline fw-semibold"
            :class="{ 'btn-disabled': !isChanged }"
            @click="restore"
          >
            Anular
          </button>
          <button class="app-btn th-bg-primary fw-semibold" @click="aplicar">
            Aplicar
          </button>
        </div>
      </div>
    </box-loader>
  </section>
</template>
<style scoped>
header {
  padding-inline: 1em;
  padding-block: 1.2em;
}
header :deep(nav:last-child) {
  padding: 0 !important;
}

.days-box {
  display: grid;
  grid-template-columns: 4rem 1.5rem;
}

.days-box > span {
  color: black;
  background-color: transparent;
  border: 1px solid #eaeaea;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.days-box button {
  font-weight: 700;
  font-size: 0.85rem;
  background-color: #02a54f;
  color: white;
  border-radius: 0.2rem;
  border: 0;
}

.box-desc {
  max-width: 18rem;
}

.btn-controls button {
  padding-block: 0.75rem;
  padding-inline: 1.25rem;
}
.btn-controls button:first-child {
  border: 2px solid #02a54f;
  color: #02a54f;
}

.btn-controls button:last-child {
  border: 2px solid #02a54f;
  background-color: #02a54f;
  color: white;
}
.btn-controls button.btn-disabled {
  border: 0;
  background-color: #dcdecb;
  cursor: auto;
  color: #a3a68c;
}
</style>
