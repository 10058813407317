<template>
  <div class="row">
    <div class="col-12 px-1">
      <h3 for="">Datos generales</h3>
    </div>
  </div>
  <div class="row" :class="bonificacion.tipo ? '' : 'laive-disabled'">
    <div class="col-md-5 col-sm-12 col-12 col-lg-5 pr-2">
      <div class="row">
        <div class="col-md-7 my-1 px-1">
          <GeneralInput
            v-model="bonificacion.descripcion"
            tipo="text"
            label="Descripción"
          ></GeneralInput>
        </div>
        <div class="col-md-5">
          <div class="row h-100">
            <div class="col-md-6 my-1 px-1">
              <DateInput
                v-model="bonificacion.inicio"
                tipo="date"
                label="Inicio"
                :disabled="es_edicion && !bonificacion.puede_modificar_inicio"
              ></DateInput>
            </div>
            <div class="col-md-6 my-1 px-1">
              <DateInput
                v-model="bonificacion.fin"
                tipo="date"
                label="Fin"
                :disabled="es_edicion && !bonificacion.puede_modificar_fin"
              ></DateInput>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7 col-sm-12 col-12 col-lg-7">
      <div class="row">
        <div class="col-md-6">
          <div
            class="row"
            v-for="prod_promo in bonificacion.prods_promo"
            :key="prod_promo"
          >
            <div class="col-md-10 col-sm-8 col-8 my-1 px-0 pl-3">
              <GeneralDropdown
                v-model="prod_promo.productos"
                tipo="date"
                label="Prod. Promocional"
                :options="productos_options"
                :is_multiple="true"
                :collapse_tags="false"
                :filterable="true"
                placeholder="Selecciona productos"
                :disabled="es_edicion"
              ></GeneralDropdown>
            </div>
            <div class="col-md-2 col-sm-4 col-4 my-1 px-1">
              <GeneralInput
                v-model="prod_promo.unidades"
                tipo="number"
                label="Unds"
                placeholder="00"
                wrapper_class="text-center"
                :disabled="es_edicion"
              ></GeneralInput>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="bonificacion.prods_regalo.length > 0">
          <div class="row">
            <div class="col-md-10 col-sm-8 col-8 my-1 px-0 pl-2">
              <GeneralDropdown
                v-model="bonificacion.prods_regalo[0].productos"
                label="Prod. Bonificacion"
                :options="productos_options"
                :is_multiple="true"
                :collapse_tags="false"
                :filterable="true"
                placeholder="Selecciona un producto"
                :disabled="es_edicion"
              ></GeneralDropdown>
            </div>
            <div class="col-md-2 col-sm-4 col-4 my-1 px-1">
              <GeneralInput
                v-model="bonificacion.prods_regalo[0].unidades"
                tipo="number"
                label="Unds"
                placeholder="00"
                wrapper_class="text-center"
                :disabled="es_edicion"
              ></GeneralInput>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import GeneralInput from "./GeneralInput.vue";
import DateInput from "./DateInput.vue";
import GeneralDropdown from "./GeneralDropdown.vue";
import constantes from "@/model/constantes.js";

const store = useStore();

const { bonificacion, es_edicion } = defineProps({
  bonificacion: {
    type: Object,
  },
  es_edicion: {
    type: Boolean,
    default: false,
  },
  productos_options: Array,
});
onMounted(() => {
  if (!es_edicion) {
    bonificacion.prods_promo = [{ productos: [], unidades: null }];
    bonificacion.prods_regalo = [
      {
        productos: [],
        unidades: null,
        tipo: constantes.TIPO_REGALO_PRODUCTO,
        valor: null,
      },
    ];
  }
});
</script>
<style lang="scss" scoped></style>
