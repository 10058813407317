export const TRACKING_STEPS = {
  REGISTRO_INCIDENCIA: "1",
  DERIVO_EQUIPO_MANTENIMIENTO: "2",
  ATENCION_INCIDENCIA: "3",
  CIERRE_INCIDENCIA: "4",
};

export const TRACKING_STATUS = {
  PENDIENTE: "1",
  DERIVO_ST: "2",
  VISTA_PROGRAMADA: "3",
  EQUIPO_EN_TALLER: "4",
  OPERATIVO: "5",
  NO_OPERATIVO: "6",
};
