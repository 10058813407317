import {
	EditableHandler,
	EditableInfo,
	GroupSelectEditable,
	InputEditable,
	SelectEditable,
} from "../../util";


const SOL_HANDLER = {
	types: {
		reportar: ["Reportar", "02"],
		recojo: ["Recojo de equipo", "01"],
		nuevo: ["Nuevo equipo de frío", "03"],
	},
	status: {
		pendiente: ["Pendiente", "0"],
		atendido: ["Atendido", "1"],
		rechazado: ["Rechazado", "3"],
	},
	getStatus(key) {
		return Object.values(this.status)
			.find(([_t, k]) => k == key)
			?.at(0);
	},
	getKeyByText(text) {
		return Object.values(this.types)
			.find(([t]) => t == text)
			?.at(1);
	},
	statusToSelect() {
		return Object.values(this.status).map((v) => ({ text: v[0], value: v[1] }));
	},
	typesToSelect() {
		return Object.values(this.types).map((v) => ({ text: v[0], value: v[1] }));
	},
};

function generateTime() {
	return new Array(24).fill(1).map((_v, i) => {
		const isAM = i + 1 < 12;
		const time = i + 1 < 13 ? i + 1 : i + 1 - 12;
		return {
			text: `${time}:00 ${isAM ? "AM" : "PM"}`,
			value: `${time}:00 ${isAM ? "AM" : "PM"}`,
		};
	});
}
/* Mapping de solicitudes  */
export function mapSolicitudesInfo(fetchData) {
	const editableHandler = new EditableHandler();

	editableHandler.add(
		"codigo",
		EditableInfo.new("Código de cliente")
			.setIsEditable(false)
			.setView(fetchData.codigoCliente),
	);
	editableHandler.add(
		"cliente",
		EditableInfo.new("Cliente").setIsEditable(false).setView(fetchData.cliente),
	);
	editableHandler.add(
		"segmento",
		EditableInfo.new("Segmento")
			.setIsEditable(false)
			.setView(fetchData.segmento),
	);

	editableHandler.add(
		"dex",
		EditableInfo.new("DEX")
			.setView(fetchData.distribuidora)
			.setIsEditable(false),
	);

	if (fetchData.tipoSolicitud == "Reportar") {
		editableHandler.add(
			"horario",
			EditableInfo.new("Horario")
				.setIsEditable(true)
				.setEditable(
					new GroupSelectEditable({
						inicio: {
							options: generateTime(),
							selected:
								fetchData.horaInicio?.length != 0
									? fetchData.horaInicio
									: "1:00 AM",
						},
						fin: {
							options: generateTime(),
							selected:
								fetchData.horaFin?.length != 0 ? fetchData.horaFin : "2:00 AM",
						},
					}),
				)
				.setView(
					`${fetchData.horaInicio ?? "---"} A ${fetchData.horaFin ?? "---"}`,
				),
		);
		editableHandler.add(
			"direccion",
			EditableInfo.new("Dirección")
				.setIsEditable(false)
				.setView(fetchData.direccion ?? "---")
				.setEditable(new InputEditable(fetchData.direccion)),
		);
		editableHandler.add(
			"tipo",
			EditableInfo.new("Tipo de solicitud")
				.setView(fetchData.tipoSolicitud ?? "--")
				.setEditable(
					new SelectEditable(
						SOL_HANDLER.typesToSelect(),
						SOL_HANDLER.getKeyByText(fetchData.tipoSolicitud),
					),
				),
		);
		editableHandler.add(
			"estado",
			EditableInfo.new("Estado")
				.setView(SOL_HANDLER.getStatus(fetchData.estado) ?? -1)
				.setEditable(
					new SelectEditable(SOL_HANDLER.statusToSelect(), fetchData.estado),
				),
		);
		editableHandler.add(
			"modelo",
			EditableInfo.new("Modelo")
				.setView(fetchData.modelo ?? "--")
				.setEditable(new InputEditable(fetchData.modelo)),
		);
		editableHandler.add(
			"placa",
			EditableInfo.new("Placa")
				.setView(fetchData.placa ?? "--")
				.setEditable(new InputEditable(fetchData.placa)),
		);
		editableHandler.add(
			"acepta",
			EditableInfo.new("¿Cliente acepta si el equipo es de laive?")
				.setView(fetchData.aceptaEquipoLaive ? "Sí" : "No")
				.setEditable(
					new SelectEditable(
						[
							{ text: "Si", value: "si" },
							{ text: "No", value: "no" },
						],
						fetchData.aceptaEquipoLaive ? "si" : "no",
					),
				),
		);
	} else {
		// Nuevo equipo/Recojo
		editableHandler.add(
			"direccion",
			EditableInfo.new("Dirección")
				.setIsEditable(true)
				.setView(fetchData.direccion ?? "---")
				.setEditable(new InputEditable(fetchData.direccion)),
		);
		editableHandler.add(
			"refUbicacion",
			EditableInfo.new("Ref. de ubicación")
				.setView(fetchData.referencia ?? "--")
				.setEditable(new InputEditable(fetchData.referencia ?? "")),
		);
		editableHandler.add(
			"horario",
			EditableInfo.new("Horario")
				.setIsEditable(true)
				.setEditable(
					new GroupSelectEditable({
						inicio: {
							options: generateTime(),
							selected:
								fetchData.horaInicio?.length != 0
									? fetchData.horaInicio
									: "1:00 AM",
						},
						fin: {
							options: generateTime(),
							selected:
								fetchData.horaFin?.length != 0 ? fetchData.horaFin : "2:00 AM",
						},
					}),
				)
				.setView(
					`${fetchData.horaInicio ?? "---"} A ${fetchData.horaFin ?? "---"}`,
				),
		);
		editableHandler.add(
			"contacto",
			EditableInfo.new("Persona de Contacto")
				.setView(fetchData.personaContacto ? fetchData.personaContacto : "--")
				.setEditable(new InputEditable(fetchData.personaContacto)),
		);
		editableHandler.add(
			"numero",
			EditableInfo.new("Número de Contacto")
				.setView(fetchData.numeroContacto ? fetchData.numeroContacto : "--")
				.setEditable(new InputEditable(fetchData.numeroContacto)),
		);
		editableHandler.add(
			"tipo",
			EditableInfo.new("Tipo de solicitud")
				.setView(fetchData.tipoSolicitud ?? "--")
				.setEditable(
					new SelectEditable(
						SOL_HANDLER.typesToSelect(),
						SOL_HANDLER.getKeyByText(fetchData.tipoSolicitud),
					),
				),
		);
		editableHandler.add(
			"estado",
			EditableInfo.new("Estado")
				.setView(SOL_HANDLER.getStatus(fetchData.estado) ?? -1)
				.setEditable(
					new SelectEditable(SOL_HANDLER.statusToSelect(), fetchData.estado),
				),
		);
	}

	return editableHandler;
}
