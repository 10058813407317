<template>
  <div class="border rounded px-2 h-100 bonificacion-form-wrapper" :class="wrapper_class">
    <div class="row mx-0 d-flex align-items-start h-100">
      <div class="col-8 px-0 d-flex row mx-0 h-100">
        <label
          class="col-form-label form-control-label pb-0 w-100"
          v-if="label"
        >
          {{ label }}
        </label>
        <div class="w-100 form-control-texto align-self-baseline"> {{ texto }} </div>
      </div>
      <div class="col-4 h-100 d-flex px-0">
        <el-date-picker
          v-model="modelValue"
          class="laive-custom-date-picker position-static"
          type="date"
          
          :clearable="false"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ElDatePicker } from "element-plus";

export default {
  components: {
    [ElDatePicker.name]: ElDatePicker,
  },
  props: {
    modelValue: Date, 
    label: String,
    tipo: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    placeholder: String,
    wrapper_class: String,
  },
  mounted() {
    this.texto = this.formatearFecha(this.modelValue);
  },
  methods: {
    formatearFecha(fecha) {
      if (!fecha) {
        return "-";
      }
      const meses = [
        "Ene.",
        "Feb.",
        "Mar.",
        "Abr.",
        "May.",
        "Jun.",
        "Jul.",
        "Ago.",
        "Sep.",
        "Oct.",
        "Nov.",
        "Dic.",
      ];

      const dia = fecha.getDate();
      const mes = meses[fecha.getMonth()];
      const anio = fecha.getFullYear();
      const dia_st = dia < 10 ? `0${dia}` : dia;
      return `${dia_st} ${mes} ${anio}`;
    },
  },
  data() {
    return {
      texto: "-",
      fecha: null,
    };
  },
  watch: {
    modelValue(nuevo, antiguo) {
      this.texto = this.formatearFecha(nuevo);
      this.$emit("update:modelValue", nuevo);
    },
  },
};
</script>

<style>
.laive-custom-date-picker .el-input__inner {
  display: none !important;
}
.el-input__prefix {
  position: unset !important;
  font-size: 20px;
}
</style>
