import { parseNormalDate } from "../../../util";

export function editableCliente(rawData, extractors) {
	if (!rawData[extractors["asignado"]]) return null;
	return {
		fecha: {
			title: "Fecha de asignación",
			type: "date",
			view: parseNormalDate(
				rawData[extractors["fecha"]]?.split(" ").shift() ?? "--/--/----",
			),
			edit: parseNormalDate(
				rawData[extractors["fecha"]]?.split(" ").shift() ?? "--/--/----",
			),
		},
		asignado: {
			title: "Asignado",
			view: rawData[extractors["asignado"]],
		},
		nombre: {
			title: "Nombre",
			view: rawData[extractors["nombre"]],
		},
		segmento: {
			title: "Segmento",
			view: rawData[extractors["segmento"]],
		},
		direccion: {
			title: "Dirección",
			view: rawData[extractors["direccion"]],
		},
		ubicacion: {
			title: "Ubicación",
			url: `https://www.google.com/maps/search/?api=1&query=${
				rawData[extractors["latitud"]]
			}%2C${rawData[extractors["longitud"]]}`,
			view: "Ir a google maps",
			type: "link",
		},
		minimo: {
			title: "Pedido Mínimo",
			type: "currency",
			view: rawData[extractors["minimo"]] ?? "--",
			edit: rawData[extractors["minimo"]] ?? "--",
		},
		comodato: {
			title: "Comodato",
			view: rawData[extractors["comodato"]] ?? "--",
			edit: rawData[extractors["comodato"]] ?? "--",
			type: "file",
			file: null,
		},
	};
}

export const EQUIPO_STATES = {
	estado: ["Asignado", "Stock", "Perdido", "Baja", "Activo"],
	condicion: ["Operativo", "Mantenimiento"],
	toOptions(section) {
		if (section == "estado") {
			return this.estado.map((est) => ({
				text: est,
				value: est.toLowerCase(),
			}));
		} else if (section == "condicion") {
			return this.condicion.map((est) => ({
				text: est,
				value: est.toLowerCase(),
			}));
		}
		return [];
	},
};

/**
 *  @function
 *  @param {object} fetchData - Data del back
 *  @param {Array} dexs - Distribuidoras (select)
 */
export function mapEquipoFrio(fetchData, dexs) {
	const optionDexs = dexs.map(({ texto, valor }) => ({
		text: texto,
		value: valor,
	}));
	const generalData = {
		placa: {
			title: "Placa",
			view: fetchData.placa,
			edit: fetchData.placa,
		},
		modelo: {
			title: "Modelo",
			view: fetchData.modelo,
		},
		lote: {
			title: "Lote",
			view: fetchData.lote,
			edit: fetchData.lote,
		},
		estado: {
			title: "Estado",
			view: fetchData.estado.trim(),
			type: "select",
			canEdit: true,
			edit: {
				options: EQUIPO_STATES.toOptions("estado"),
				selected: fetchData.estado.trim(),
			},
		},
		dex: {
			title: "DEX",
			view: fetchData.idDistribuidora ? fetchData.distribuidora : "---",
			id: fetchData.idDistribuidora,
			type: "select",
			canEdit: true,
			edit: {
				options: optionDexs.concat({ text: "Selecciona", value: -1 }),
				selected: fetchData.idDistribuidora ?? -1,
			},
		},
		fecha: {
			title: "Fecha de actualización",
			view: fetchData.fechaActualizacion ? fetchData.fechaActualizacion.split(" ")[0] : "--/--/----",
		},
		condicion: {
			title: "Condición",
			view: fetchData.condicion ?? "---",
			type: "select",
			canEdit: true,
			edit: {
				options: EQUIPO_STATES.toOptions("condicion"),
				selected: fetchData.condicion ?? "operativo",
			},
		},
	};

	const clientMap = {
		fecha: "fechaAsignacion",
		asignado: "clienteAsignado",
		nombre: "clienteNombre",
		segmento: "clienteSegmento",
		direccion: "clienteDireccion",
		latitud: "clienteLatitud",
		longitud: "clienteLongitud",
		minimo: "clientePedidoMinimo",
		comodato: "clienteComodato",
	};

	return {
		general: generalData,
		client: editableCliente(fetchData, clientMap),
	};
}
