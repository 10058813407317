<template>
  <box-loader
    class="card-table form_laive"
    :class="[{ 'card-default-styles': withDefaultStyles }, containerClass]"
    :loading="loading"
  >
    <header class="card-table-header" :class="{ withBorder: showFilters }">
      <slot name="header">
        <h2 class="card-table-title m-0">
          {{ headerTitle }}
        </h2>
        <button
          class="th-btn-pry th-btn-float"
          @click.stop="$emit('headerBtnClick')"
          v-if="showButton && buttonType === 'button'"
        >
          <span>{{ buttonText }}</span>
          <i class="laive-i icon-excel-download" />
        </button>
        <router-link
          class="th-btn-pry th-btn-float"
          @click.stop="$emit('headerBtnClick')"
          v-if="showButton && buttonType === 'link'"
          :to="buttonLink"
        >
          <span>{{ buttonText }}</span>
          <i class="laive-i icon-excel-download" />
        </router-link>
      </slot>
    </header>
    <section class="card-table-filters" v-if="showFilters">
      <slot name="filters"> </slot>
    </section>
    <section class="card-table-content scroller">
      <slot>
        <div class="b-table sticky-header scroller table-h">
          <div class="t-header">
            <div class="t-row">
              <div
                class="t-cell justify-content-center"
                v-for="header in tableData.headers"
                :key="header.label"
                :style="{ minWidth: header.minWidth ?? 'auto' }"
              >
                <span>{{ header.label }}</span>
              </div>
            </div>
          </div>
          <div v-if="tableData.rows.length > 0" class="t-body">
            <div class="t-row" v-for="row in tableData.rows">
              <div
                class="t-cell"
                v-for="{ key } in tableData.headers"
                :key="key"
                :class="[getPosition(row.meta?.position)]"
              >
                {{ row[key] }}
              </div>
            </div>
          </div>
          <div
            v-if="tableData.rows.length == 0 && showEmptyMessage"
            class="t-nt-found"
          >
            <img src="/img/shared/search.svg" alt="No encontrado" />
            <p class="text">{{ emptyMessage ?? "No hay datos que mostrar" }}</p>
          </div>
        </div>
      </slot>
    </section>
  </box-loader>
</template>

<script>
const positions = {
  left: "justify-content-start",
  right: "jusstify-content-end",
  center: "justify-content-center",
  DEFAULT: "justify-content-center",
};
export default {
  props: {
    withDefaultStyles: {
      type: Boolean,
      default: true,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showEmptyMessage: {
      type: Boolean,
      default: false,
    },
    containerClass: String,
    headerTitle: String,
    showButton: {
      type: Boolean,
      default: true,
    },
    buttonText: String,
    buttonLink: String,
    buttonType: {
      type: String,
      default: "button",
      validator(btnType) {
        return ["button", "link", "none"].includes(btnType);
      },
    },
    emptyMessage: String,
    tableData: {
      type: Object,
      default: {
        headers: [],
        rows: [],
      },
    },
  },
  methods: {
    getPosition(position) {
      const classPosition = positions[position];
      return classPosition ?? positions.DEFAULT;
    },
  },
};
</script>
<style scoped>
.card-table {
  /*overflow: hidden;*/
  border-radius: 0.5em 0.5em 0 0;
  background-color: white;
  box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.25);
}

.card-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.85em 1.2em;
}

.card-table-header.withBorder {
  border-bottom: 1px solid #e5e5e5;
}

.card-table-header :is(button, a) {
  font-size: 0.85rem;
}

.card-table-header :is(button i, a i) {
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
}

.card-table-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.6em;
  padding: 1em 1em;
}

.card-table-content {
  overflow: auto;
}

.card-default-styles .card-table-filters :deep(:is(.title_filter)) {
  color: #6e6e6e;
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0.6em;
}

.card-default-styles .card-table-filters :deep(input.field) {
  padding-inline: 1.1em;
  color: black;
  font-weight: 300;
}

.card-default-styles .card-table-filters :deep(input.field::placeholder) {
  color: black !important;
}

.card-table-filters :deep(> *) {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.card-table-filters :deep(.dropdown-container input) {
  flex: 1;
}

.card-table-title {
  color: black;
  font-size: 1.1rem;
}
</style>
