import { mapActions, mapGetters } from 'vuex';
import LiquidacionService from '../services/LiquidacionService';
import axios from 'axios';
import constantes from '../model/constantes';


const agruparPorDisitribuidora = (data) => {
    const distribuidoras = [];
    data.forEach(liquidacion => {
        const existente = distribuidoras.find(distribuidora => distribuidora.iddistribuidora == liquidacion.iddistribuidora);
        if (!existente) {
            distribuidoras.push({
                iddistribuidora: liquidacion.iddistribuidora,
                nombre_distribuidora: liquidacion.nombre_distribuidora,
                liquidaciones: [liquidacion],
            })
        } else {
            existente.liquidaciones.push(liquidacion);
        }
    });
    return distribuidoras;
}

const generarActividadVenta = (liquidacion) => {
    const fecha_actual = new Date();
    return {
        costo_total_ajustado: liquidacion.costo_total_ajustado,
        bonificacion: [
            {
                nombre: liquidacion.nombre_bonificacion,
                tipo: liquidacion.tipo_bonificacion,
                distribuidor: [
                    {
                        iddistribuidora: liquidacion.iddistribuidora,
                        fechainicio: fecha_actual,
                        fechafin: fecha_actual,
                        // giro: "",
                        // segmento: "",
                        // tipocliente: "",
                        // tipovendedor: "",
                        // idvendedor: "",
                        precios: [],
                        // totalcliente: 0,
                        // costoproductos: 0,
                        // costoproductosund: 0,
                        // costoregalos: 0,
                        // costoregalosund: 0
                    }
                ],
                grupoProducto: [],
                regalo: [
                    {
                        cantidad: liquidacion.cantidadbhgr,
                        tipo: "producto",
                        // valor: null,
                        regaloProductos: [
                            {
                                codigoproducto: liquidacion.codigoproductopr
                            }
                        ]
                    }
                ]
            }
        ]
    }


}




export default {
    namespaced: true,
    state: {
        liquidaciones_jop: [],
        liquidaciones_subgerente: [],
        liquidaciones_gerente: [],
        mensajes: [],
    },
    getters: {},
    mutations: {
        agregarMensaje(state, mensaje) {
            state.mensajes.push(mensaje);
        },
        cambiarOpacity(state, mensajeId) {
            const mensaje = state.mensajes.find(item => item.id == mensajeId);
            if (mensaje) {
                mensaje.opacidad = 0;
            }
        },
        eliminarMensaje(state, mensajeId) {
            state.mensajes = state.mensajes.filter(item => item.id != mensajeId)
        },
        filtrarListaJOP(state, payload) {
            state.liquidaciones_jop = state.liquidaciones_jop.filter(item => item.idbonificacionbonhistoricodistribuidora != payload.idbonificacionbonhistoricodistribuidora);
        }
    },
    methods: {},
    actions: {

        async obtenerLiquidacionesJOP({ state, commit }, request) {
            const data = await LiquidacionService.obtenerLiquidacionesJOP(axios, request);
            state.liquidaciones_jop = data;
        },
        async obtenerLiquidacionesSubGerente({ state, commit }, request) {
            const data = await LiquidacionService.obtenerLiquidacionesJOP(axios, request);
            state.liquidaciones_subgerente = agruparPorDisitribuidora(data);
        },
        async obtenerLiquidacionesGerente({ state, commit }, request) {
            const data = await LiquidacionService.obtenerLiquidacionesGerente(axios, request);
            state.liquidaciones_gerente = data;
        },
        async actualizarEstado({ state, commit }, payload) {
            return await LiquidacionService.actualizarEstado(axios, payload);
        },
        async actualizarLiquidacion({ state, commit }, payload) {
            return await LiquidacionService.actualizarLiquidacion(axios, payload);
        },
        async crearActividadVenta({ state, commit }, liquidacion) {
            const actividad = generarActividadVenta(liquidacion);
            return await LiquidacionService.crearActividadVenta(axios, actividad);
        },
        async eliminarActVenta({ state, commit }, payload) {
            const result = await LiquidacionService.eliminarActVenta(axios, payload);
            commit('filtrarListaJOP', payload);
            return result;
        },
        async CrearMemo({ state, commit }, payload) {
            return await LiquidacionService.CrearMemo(axios, payload);
        },
        async actualizarEstadoMemo({ state, commit }, payload) {
            return await LiquidacionService.actualizarEstadoMemo(axios, payload);
        },
        async DescargarLiquidacionesJOP({ state, commit }, payload) {
            return await LiquidacionService.DescargarLiquidacionesJOP(axios, payload);
        },
        async DescargarMemoExcel({ state, commit }, request) {
            return await LiquidacionService.DescargarMemoExcel(axios, request);
        },
        async DescargarMemoWord({ state, commit }, request) {
            return await LiquidacionService.DescargarMemoWord(axios, request);
        }


    }
}