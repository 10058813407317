<template>
  <div class="row mt-3">
    <div class="col-md-5 px-1">
      <h3 for="">
        {{
          bonificacion.tipo == constantes.TIPO_REBATE
            ? "Cargar clientes y objetivos a aplicar"
            : "Seleccionar clientes"
        }}
      </h3>
    </div>
  </div>

  <div class="row my-2" v-if="bonificacion.tipo != 'rebate'">
    <div class="col-1 p-0">
      <div class="w-100 h-100 d-flex justify-content-center align-items-center">
        <button
          class="bonificacion-radio-btn p-0 m-0 d-flex justify-content-center align-items-center"
          @click.prevent="bonificacion.carga_masiva = false"
          :class="bonificacion.carga_masiva == false ? 'activo' : ''"
          :disabled="!bonificacion.productos_configurados"
        >
          <span class="bonificacion-radio-btn-content"></span>
        </button>
      </div>
    </div>
    <div class="col-11 p-2 bonificacion-cliente-row" v-if="valores_mapeados">
      <div
        class="row px-2"
        :class="
          bonificacion.carga_masiva == false && bonificacion.tipo
            ? ''
            : 'laive-disabled'
        "
      >
        <div class="col-md-2 px-1">
          <GeneralDropdown
            v-model="bonificacion.distribuidoras"
            label="DEX"
            aditional_class="bg-white"
            :options="distribuidoras_options"
            :is_multiple="true"
            :collapse_tags="true"
            :puede_seleccionar_todos="true"
            :resumir_tags="true"
            :disabled="!puede_seleccionar_clientes ||es_edicion"
            :fit_content="true"
          ></GeneralDropdown>
        </div>
        <div class="col-md-2 px-1">
          <GeneralDropdown
            v-model="bonificacion.tipos_vendedor"
            label="Tipo de vendedor"
            aditional_class="bg-white"
            :options="tipos_vendedor_options"
            :is_multiple="true"
            :collapse_tags="true"
            :puede_seleccionar_todos="true"
            :resumir_tags="true"
            :disabled="!puede_seleccionar_clientes || es_edicion"
            :fit_content="true"
          ></GeneralDropdown>
        </div>
        <div class="col-md-2 px-1">
          <GeneralDropdown
            v-model="bonificacion.vendedores"
            label="Vendedor"
            aditional_class="bg-white"
            :options="vendedor_options"
            :is_multiple="true"
            :collapse_tags="true"
            :puede_seleccionar_todos="true"
            :resumir_tags="true"
            :disabled="!puede_seleccionar_clientes || es_edicion"
            :fit_content="true"
          ></GeneralDropdown>
        </div>
        <div class="col-md-2 px-1">
          <GeneralDropdown
            v-model="bonificacion.giros_negocio"
            label="Giro"
            aditional_class="bg-white"
            :options="giros_negocio_options"
            :is_multiple="true"
            :collapse_tags="true"
            :puede_seleccionar_todos="true"
            :resumir_tags="true"
            :disabled="!puede_seleccionar_clientes || es_edicion"
            :fit_content="true"
          ></GeneralDropdown>
        </div>
        <div class="col-md-2 px-1">
          <GeneralDropdown
            v-model="bonificacion.segmentos"
            label="Segmento"
            aditional_class="bg-white"
            :options="segmentos_options"
            :is_multiple="true"
            :collapse_tags="true"
            :puede_seleccionar_todos="true"
            :resumir_tags="true"
            :disabled="!puede_seleccionar_clientes || es_edicion"
            :fit_content="true"
          ></GeneralDropdown>
        </div>
        <div class="col-md-2 px-1">
          <GeneralDropdown
            v-model="bonificacion.tipos_cliente"
            label="Tipo de cliente"
            aditional_class="bg-white"
            :options="tipos_cliente_options"
            :is_multiple="true"
            :collapse_tags="true"
            :puede_seleccionar_todos="true"
            :resumir_tags="true"
            :disabled="!puede_seleccionar_clientes || es_edicion"
            :fit_content="true"
          ></GeneralDropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="row my-2">
    <div class="col-1 p-0" v-if="bonificacion.tipo != 'rebate'">
      <div class="w-100 h-100 d-flex justify-content-center align-items-center">
        <button
          class="bonificacion-radio-btn p-0 m-0 d-flex justify-content-center align-items-center"
          @click.prevent="bonificacion.carga_masiva = true"
          :class="bonificacion.carga_masiva == true ? 'activo' : ''"
          :disabled="!bonificacion.productos_configurados"
        >
          <span class="bonificacion-radio-btn-content"></span>
        </button>
      </div>
    </div>
    <div
      class="p-2 bonificacion-cliente-row"
      :class="{
        'col-11': bonificacion.tipo != constantes.TIPO_REBATE,
        'col-12': bonificacion.tipo == constantes.TIPO_REBATE,
      }"
    >
      <div class="row">
        <div class="col-md-12 px-0">
          <div
            class="row w-100 mx-0 px-4"
            :class="{
              'laive-disabled': bonificacion.carga_masiva != true,
            }"
          >
            <label
              class="col-form-label form-control-label px-1 d-flex align-items-center"
              >Carga Masiva de clientes</label
            >
            <div
              class="ml-auto d-flex align-items-center"
              v-if="bonificacion.tiene_documento_valido == true"
            >

              <Chip
                aditional_class="chip-primary bonificacion-primary-text"
                :texto="bonificacion.excel_name"
                :texto_posterior="archivo_size"
              ></Chip>
              <div class="remove-excel-btn" @click.prevent="limpiarExcelData" v-if="bonificacion.datos_extraidos?.length>0">
                <i
                  class="ml-2 far fa-times-circle bonificacion-primary-text"
                ></i>
              </div>
            </div>
            <div class="ml-auto" v-else>
              <a
                href="#"
                class="btn-link mx-4 bonificacion-btn-text"
                @click.prevent="descargarPlantilla"
                :disabled="bonificacion.carga_masiva != true"
                >Descargar Plantilla</a
              >
              <base-button
                @click.prevent="cargarArchivo"
                type="success bonificacion-btn-text"
                class="border-0"
                :disabled="bonificacion.carga_masiva != true"
              >
                Cargar Plantilla
              </base-button>
              <input
                ref="inputArchivo"
                type="file"
                accept=".xlsx"
                style="display: none"
                @change="handleFileChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row my-2">
    <div class="col-12">
      <ul>
        <li v-for="mensaje in mensajes" :key="mensaje">
          <small>
            {{ mensaje }}
          </small>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import GeneralDropdown from "./GeneralDropdown.vue";
import Chip from "./Chip.vue";

import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import * as XLSX from "xlsx";
import constantes from "@/model/constantes.js";
const store = useStore();

const { bonificacion, tipos, index, puede_eliminar, es_edicion } = defineProps({
  bonificacion: {
    type: Object,
  },
  tipos: {
    type: Array,
    default: [],
  },
  index: {
    type: Number,
  },  
  es_edicion: {
    type: Boolean,
    default: false,
  },
});

const archivo_seleccionado = ref(null);

const valores_mapeados = ref(false);
onMounted(() => {
  
  if (!es_edicion) {
    bonificacion.distribuidoras = distribuidoras_options.value.map(
      (item) => item.value
    );
    bonificacion.tipos_vendedor = tipos_vendedor_options.value.map(
      (item) => item.value
    );
    bonificacion.vendedores = vendedor_options.value.map((item) => item.value);
  
    bonificacion.giros_negocio = giros_negocio_options.value.map(
      (item) => item.value
    );
  
    bonificacion.segmentos = segmentos_options.value.map((item) => item.value);
    bonificacion.tipos_cliente = tipos_cliente_options.value.map(
      (item) => item.value
    );
    
    calcularClientes();    
  }
  valores_mapeados.value = true;

});

const distribuidoras = computed(
  () => store.state.bonificacionUtil.distribuidoras
);
const vendedores = computed(() => {
  let fuente = store.state.bonificacionUtil.vendedores;
  fuente = fuente.filter((item) =>
    bonificacion.distribuidoras?.includes(item.iddistribuidora)
  );
  return fuente.filter((item) =>
    bonificacion.tipos_vendedor?.includes(item.tipovendedor.toUpperCase())
  );
});
const tiposCliente = computed(() => store.state.bonificacionUtil.tiposCliente);
const segmentos = computed(() => store.state.bonificacionUtil.segmentos);
const girosNegocio = computed(() => store.state.bonificacionUtil.girosNegocio);
const tiposVendedor = computed(
  () => store.state.bonificacionUtil.tiposVendedor
);

const distribuidoras_options = computed(() =>
  distribuidoras.value.map((item) => {
    return {
      value: item.iddistribuidora,
      label: item.codigodistribuidora,
    };
  })
);
const tipos_vendedor_options = computed(() =>
  tiposVendedor.value.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  })
);
const vendedor_options = computed(() =>
  vendedores.value.map((item) => {
    return {
      value: `${item.iddistribuidora}-${item.codigovendedor}`,
      label: `${item.primernombre} ${item.apellidopaterno} ${item.apellidomaterno}`,
    };
  })
);
const giros_negocio_options = computed(() =>
  girosNegocio.value.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  })
);
const segmentos_options = computed(() =>
  segmentos.value.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  })
);
const tipos_cliente_options = computed(() =>
  tiposCliente.value.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  })
);
const excel_cargado = ref(false);

onMounted(() => {
  if(bonificacion?.excel_path?.trim()!=""){
    bonificacion.tiene_documento_valido = true;
  }
});

const puede_seleccionar_clientes = computed(() => {
  return bonificacion.carga_masiva == false;
});

const archivo_size = computed(() => {
  if (bonificacion.excel_size) {
    const mb_size = bonificacion.excel_size / (1024 * 1024);
    return `${mb_size.toFixed(2)} mb`;
  } else return "";
});

watch(
  () => bonificacion.distribuidoras,
  (nuevo) => {
    bonificacion.vendedores = bonificacion.vendedores?.filter((item) =>
      vendedor_options.value.map((option) => option.value).includes(item)
    );
    calcularClientes();
  }
);
watch(
  () => bonificacion.tipos_vendedor,
  (nuevo) => {
    bonificacion.vendedores = bonificacion.vendedores?.filter((item) =>
      vendedor_options.value.map((option) => option.value).includes(item)
    );
    calcularClientes();
  }
);
watch(
  () => bonificacion.giros_negocio,
  (nuevo) => {
    calcularClientes();
  }
);
watch(
  () => bonificacion.tipos_cliente,
  (nuevo) => {
    calcularClientes();
  }
);
watch(
  () => bonificacion.vendedores,
  (nuevo) => {
    // calcularClientes();
  }
);
watch(
  () => bonificacion.tipo,
  (nuevo) => {
    if (nuevo == "rebate") {
      bonificacion.carga_masiva = true;
    }
  }
);
watch(
  () => bonificacion.carga_masiva,
  (nuevo) => {
    limpiarExcelData();
  }
);

const calcularClientes = () => {
  const payload = {
    idDistribuidora: bonificacion.distribuidoras?.join(","),
    giroNegocio: bonificacion.giros_negocio?.join(","),
    codigoTipoCliente: bonificacion.tipos_cliente?.join(","),
    // codigoVendedor: bonificacion.vendedores
    //   .map((item) => item.split("-")[1])
    //   .join(","),    
    // tipoVendedor: bonificacion.tipos_vendedor.join(","),
    segmentos: bonificacion.segmentos?.join(",")
  };
  store.dispatch("bonificacionUtil/contarCientes", payload);
};

const datosExtraidos = ref([]);
const inputArchivo = ref("inputArchivo");

const obtenerEncabezados = () => {
  const headers = ["DEX", "CLIENTE"];
  if (bonificacion.tipo == constantes.TIPO_REBATE) {
    headers.push("OBJETIVO");
  }
  return headers;
};

const descargarPlantilla = () => {
  const headers = obtenerEncabezados();
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet([headers]);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Plantilla");
  XLSX.writeFile(workbook, "plantilla.xlsx");
};

const cargarArchivo = () => {
  inputArchivo.value.click();
};

const handleFileChange = (event) => {
  limpiarExcelData();
  const archivo = event.target.files[0];
  archivo_seleccionado.value = archivo;
  const lector = new FileReader();

  lector.onload = async (e) => {
    const contenido = e.target.result;
    extraerColumnas(contenido);
    const datos_validos = validarDatos();

    if (datos_validos) {
      const file_wrapper = new FormData();
      file_wrapper.append("file", archivo_seleccionado.value);
      const upload_result = await store.dispatch(
        "bonificacion/subiArchivo",
        file_wrapper
      );

      if (upload_result.status == 200) {
        bonificacion.tiene_documento_valido = datos_validos;
        bonificacion.datos_extraidos = datosExtraidos.value;
        bonificacion.excel_path = upload_result.message;
        bonificacion.excel_name = archivo_seleccionado.value.name;
        bonificacion.excel_size = archivo_seleccionado.value.size;
      } else {
        console.error("upload_result", upload_result);
      }
    }
  };

  lector.readAsBinaryString(archivo);
};

const limpiarExcelData = () => {
  bonificacion.tiene_documento_valido = false;
  bonificacion.datos_extraidos = [];
  bonificacion.excel_path = "";
  bonificacion.excel_name = "";
  bonificacion.excel_size = null;
};

const extraerColumnas = (contenido) => {
  const workbook = XLSX.read(contenido, { type: "binary" });
  const primeraHoja = workbook.Sheets[workbook.SheetNames[0]];
  const datos = XLSX.utils.sheet_to_json(primeraHoja, { header: 1 });
  const columnasDeseadas = obtenerEncabezados();
  const indicesColumnasDeseadas = columnasDeseadas.map((columna) =>
    datos[0].indexOf(columna)
  );

  datosExtraidos.value = datos.map((fila) =>
    indicesColumnasDeseadas.map((indice) => fila[indice])
  );

  datosExtraidos.value = datosExtraidos.value
    .filter(item => item.every(value => value.toString().trim() != ""));

  datosExtraidos.value.shift();
};


const mensajes = ref([]);
const validarDatos = () => {
  mensajes.value = [];
  if (datosExtraidos.value.length == 0) {
    mensajes.value.push(`No existen datos en el documento proporcionado.`);
    return false;
  }
  const validar_objetivo = bonificacion.tipo == constantes.TIPO_REBATE;
  const valores_distribuidoras = distribuidoras_options.value.map(
    (option) => option.value
  );
  return datosExtraidos.value.every((item, index) => {
    const distribuidora_valida = valores_distribuidoras.includes(item[0]);
    if (!distribuidora_valida) {
      mensajes.value.push(
        `Distribuidora ${item[0]} no es válida en la linea ${index + 2}.`
      );
    }
    if (validar_objetivo) {
      const objetivo_valido = item[2] > 0;
      if (!objetivo_valido) {
        mensajes.value.push(`Objetivo no es válida en la linea ${index + 2}.`);
      }
      return objetivo_valido && distribuidora_valida;
    }
    return distribuidora_valida;
  });
};
</script>

<style lang="scss" scoped>
.remove-excel-btn i {
  cursor: pointer;
}
</style>
