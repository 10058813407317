<template>
  <custom-modal
    :show="modals.confirm.show"
    :isHeader="false"
    width="380"
    contentClass="p-3 pt-4"
    @modal:close="modalsClose('confirm')"
    :overLevel="99999"
    showCloseIcon
  >
    <template #body>
      <section class="p-2">
        <header class="th-flex-r mb-3" style="--justify: center">
          <h3 class="text-dark text-center" :style="{ width: '80%' }">
            ¿Estás seguro de cambiar los datos?
          </h3>
        </header>
        <div class="th-flex-r" style="--justify: center">
          <button
            class="th-btn th-btn-border-pry"
            @click="modalsClose('confirm')"
          >
            Cancelar
          </button>
          <button class="th-btn-float th-btn-pry" @click="acceptConfirm">
            Sí, estoy seguro
          </button>
        </div>
      </section>
    </template>
  </custom-modal>
  <custom-modal
    :show="modals.alert.show"
    :isHeader="false"
    width="380"
    contentClass="p-3 pt-4"
    @modal:close="modalsClose('alert')"
    :overLevel="99999"
    showCloseIcon
  >
    <template #body>
      <section class="p-2">
        <header class="th-flex-r mb-3" style="--justify: center">
          <h3 class="text-dark text-center" :style="{ width: '80%' }">
            Debes elegir un cliente
          </h3>
        </header>
        <div class="th-flex-r" style="--justify: center">
          <button class="th-btn-float th-btn-pry" @click="modalsClose('alert')">
            Entendido
          </button>
        </div>
      </section>
    </template>
  </custom-modal>
  <custom-modal
    :show="show"
    :isHeader="true"
    width="500"
    contentClass="px-4"
    @modal:close="closeModal()"
    showCloseIcon
  >
    <template #header>
      <h3 class="th-text-primary text-uppercase">DETALLE DE EQUIPO DE FRÍO</h3>
    </template>
    <template #body>
      <box-loader
        containerClass="body-modal text-dark"
        :loading="loading"
        minHeight="130px"
      >
        <template v-if="general">
          <!-- Datos generales -->
          <section class="info-container mb-2">
            <h3>Datos Generales</h3>
            <div class="info-group">
              <template v-for="info in general">
                <div :class="{ 'edit-group': isEditMode }" v-if="!info.hidden">
                  <h4>{{ info.title }}</h4>
                  <Select
                    v-if="isEditMode && info.type == 'select'"
                    v-model:selected="info.edit.selected"
                    :options="info.edit.options"
                    :disabled="!info.canEdit"
                  ></Select>

                  <input
                    v-else-if="isEditMode && info.canEdit"
                    v-model="info.edit"
                    class="editable mr-1"
                    spellcheck="false"
                  />
                  <span class="d-block" v-else>
                    {{ info.view }}
                  </span>
                </div>
              </template>
            </div>
          </section>
          <!-- Datos generales -->
          <!-- Datos del cliente  -->
          <section class="info-container mb-2" v-if="checkShow('clienteBlock')">
            <template v-if="checkShow('buscador')">
              <h3 class="mb-2">Asignar a un cliente</h3>
              <div class="th-flex-r mb-3" :style="{ fontSize: '0.850rem' }">
                <InputSelect
                  value="''"
                  placeholder="Nombre o código del cliente"
                  v-model="buscador.name"
                  :options="buscador.options"
                  @update:modelValue="searchClient"
                  @selectOption="selectedClient"
                  :style="{ minWidth: '260px' }"
                />
              </div>
            </template>
            <template v-if="checkShow('cliente')">
              <header
                class="th-flex-r separate"
                v-if="checkShow('clienteHeader')"
              >
                <h3>Datos del Cliente</h3>
                <button
                  @click="openSearch"
                  class="th-link-pry text-xs"
                  v-if="checkShow('clienteCambiar')"
                  :style="{ marginTop: '-0.4em' }"
                >
                  Cambiar de cliente
                  <i class="th-icon laive-i icon-arrow-right" />
                </button>
              </header>
              <div class="info-group" v-if="clienteInfo">
                <div
                  v-for="info in clienteInfo"
                  :class="{ 'edit-group': isEditMode }"
                >
                  <h4>{{ info.title }}</h4>
                  <div
                    v-if="isEditMode && info.type == 'file'"
                    class="info-item-block text-nowrap d-flex align-items-center"
                  >
                    <input
                      v-model="info.edit"
                      class="editable mr-1"
                      spellcheck="false"
                      disabled
                    />
                    <label role="button" class="th-link-pry m-0">
                      <input
                        class="th-link-pry"
                        type="file"
                        style="display: none"
                        accept=".pdf"
                        @change="handleSelectFile(info, $event)"
                      />
                      Adjuntar archivo
                    </label>
                  </div>
                  <DateSelect
                    v-else-if="
                      isEditMode && info.type == 'date' && info.canEdit
                    "
                    v-model="info.edit"
                    containerClass="editable"
                    :showIcon="false"
                  />
                  <div
                    v-else-if="
                      isEditMode && info.canEdit && info.type == 'currency'
                    "
                    class="d-flex align-items-end"
                  >
                    <input
                      v-model="info.edit"
                      class="editable mr-1"
                      spellcheck="false"
                    />
                    <span :style="{ padding: 0 }">soles</span>
                  </div>
                  <input
                    v-else-if="isEditMode && info.canEdit"
                    v-model="info.edit"
                    class="editable mr-1"
                    spellcheck="false"
                  />
                  <a
                    v-else-if="info.type == 'link'"
                    class="th-link-pry text-truncate d-block"
                    :href="info.url"
                    target="_blank"
                  >
                    <i class="fa fa-map-marker-alt mr-2" />
                    {{ info.view }}
                  </a>
                  <span class="d-block" v-else>
                    {{ info.type == "currency" ? "s/" : "" }} {{ info.view }}
                  </span>
                </div>
              </div>
            </template>
          </section>
          <!-- End Datos del cliente  -->
          <div class="th-flex-r modal-buttons separate text-xs">
            <router-link
              v-if="
                user.rol === 1 ||
                user.rol === 2 ||
                user.rol === 3 ||
                user.rol === 4
              "
              to="/equipos-frio/maestro-activos?tab=incidencias"
              class="th-link-pry"
              @click.prevent="changeTab()"
            >
              Ver incidencias Reportadas
            </router-link>
            <button
              class="th-btn th-btn-border-pry"
              @click.stop="editData"
              v-if="(user.rol === 1 || user.rol === 2) && mode === 0"
            >
              Editar
            </button>
            <div v-if="isEditMode">
              <button class="th-btn th-btn-border-pry" @click.stop="cancel">
                Cancelar
              </button>
              <button
                class="th-btn-float th-btn-pry ml-2"
                @click.stop="saveData"
              >
                Guardar
              </button>
            </div>
          </div>
        </template>
      </box-loader>
    </template>
  </custom-modal>
</template>

<script>
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
import { Select, DateSelect, InputSelect } from "../../../components/selects";
import CustomModal from "../../Components/CustomModal.vue";
import { debounce } from "../../../util";
import { Equipos, Clientes } from "../../../services";
import { mapEquipoFrio, editableCliente, EQUIPO_STATES } from "./data_mapping";

const DETAILS_MODE = {
  VIEW: 0,
  EDIT: 1,
};

const CLIENTE_MODE = {
  DEFAULT: 0, // Editar el cliente
  SEARCH: 1, // Buscar un cliente a asignar
  SELECTED: 2, // Buscar y editar el cliente a la ves
};

export default {
  components: {
    CustomModal,
    DateSelect,
    InputSelect,
    Select,
  },
  emits: ["update:show", "save"],
  data() {
    return {
      //Detalles del equipo
      general: null,
      // Detalle del cliente
      cliente: null,
      // Modo del cliente (editar,buscar,ambos)
      clienteMode: CLIENTE_MODE.DEFAULT,
      buscador: {
        loading: false,
        name: "",
        cliente: null,
        options: [],
      },
      loading: false,
      mode: DETAILS_MODE.VIEW,
      modals: {
        confirm: {
          show: false,
          accept: false,
        },
        alert: {
          show: false,
        },
      },
    };
  },
  props: {
    distribuidoras: Array,
    equipoId: Number,
    show: Boolean,
  },
  computed: {
    isEditMode() {
      return this.mode == DETAILS_MODE.EDIT;
    },
    clienteInfo() {
      if (this.mode == DETAILS_MODE.VIEW) return this.cliente;
      // Modo de edicion
      if (this.clienteMode == CLIENTE_MODE.DEFAULT) return this.cliente;
      if (this.clienteMode == CLIENTE_MODE.SELECTED)
        return this.buscador.cliente;
      return null;
    },
    ...mapGetters({
      user: "auth/user",
    }),
  },
  watch: {
    "general.estado.edit.selected"(newEstado) {
      if (!this.general) return;
      if (newEstado == "stock") {
        this.general.estado.canEdit = true;
        this.general.condicion.canEdit = false;
        this.general.condicion.edit = {
          options: EQUIPO_STATES.toOptions("condicion").slice(0, 1),
          selected: "operativo",
        };
        this.general.dex.edit.selected = -1;
        this.general.dex.hidden = true;
        // Cambiar al "buscador"
        this.clienteMode = CLIENTE_MODE.SEARCH;
      } else {
        this.general.estado.canEdit = true;
        this.general.condicion.canEdit = true;
        this.general.dex.hidden = false;
      }

      if (newEstado == "perdido" || newEstado == "baja") {
        this.general.condicion.hidden = true;
        this.general.estado.canEdit = true;
        this.general.dex.canEdit = false;
        this.clienteMode = CLIENTE_MODE.DEFAULT;
      } else {
        this.general.dex.canEdit = true;
        this.general.condicion.hidden = false;
      }

      if (newEstado == "baja") {
        this.general.dex.edit.selected = -1;
      }
      if (newEstado == "perdido") {
        this.general.dex.edit.selected = this.general.dex.id ?? -1;
      }
      if (newEstado == "asignado") {
        this.general.condicion.edit = {
          options: EQUIPO_STATES.toOptions("condicion"),
          selected: "operativo",
        };
      }
    },
    "general.dex.edit.selected"(newDex, oldDex) {
      if (!oldDex || !this.general) return;
      if (newDex == -1) {
        const estado = this.general.estado.edit.selected;
        if (estado == "baja") return;
        if (estado != "stock") {
          // Cambiarlo a stock;
          this.general.estado.edit.selected = "stock";
        }
      }
      if (newDex != this.general.dex.id) {
        this.openSearch();
      }
      this.resetBuscador();
    },
    equipoId(id) {
      if (id) {
        this.loading = true;
        const token = this.$store.state.auth.token;
        if (!token) return;
        Equipos.getDetails(token, id)
          .then((data) => {
            const { general, client } = mapEquipoFrio(
              data,
              this.distribuidoras
            );
            this.general = general;
            this.cliente = client;
          })
          .catch(() => {
            this.toast("No se pudo obtener la informacion", "error");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  methods: {
    openSearch() {
      this.clienteMode = CLIENTE_MODE.SEARCH;
    },
    checkShow(type) {
      if (type == "buscador") {
        if (this.mode == DETAILS_MODE.VIEW) return false;
        if (this.general.dex.edit.selected == -1) return false;
        return (
          this.clienteMode != CLIENTE_MODE.DEFAULT &&
          this.general.estado.edit.selected !== "perdido"
        );
      }
      if (type == "clienteBlock") {
        if (this.mode == DETAILS_MODE.VIEW) return !!this.cliente;
        if (this.general.dex.edit.selected == -1) return false;
        return true;
      }
      if (type == "cliente") {
        if (this.mode == DETAILS_MODE.VIEW) return !!this.cliente;
        return this.clienteMode != CLIENTE_MODE.SEARCH;
      }
      if (type == "clienteHeader") {
        if (this.mode == DETAILS_MODE.VIEW) return true;
        if (!this.clienteInfo || !this.cliente) return false;
        return this.clienteInfo.asignado.view == this.cliente.asignado.view;
      }
      if (type == "clienteCambiar") {
        if (this.mode == DETAILS_MODE.VIEW) return false;
        return (
          this.general.estado.edit.selected == "asignado" &&
          this.clienteInfo.asignado.view == this.cliente.asignado.view
        );
      }
    },
    toast(title, type = "success") {
      const toast = useToast();
      if (type == "success") {
        toast.success(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-pry",
          showCloseButtonOnHover: true,
        });
      } else if (type == "error") {
        toast.error(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-error",
          showCloseButtonOnHover: true,
        });
      }
    },
    modalsOpen(select) {
      if (select == "confirm") {
        this.modals.confirm.show = true;
      } else if (select == "alert") {
        this.modals.alert.show = true;
      }
    },
    modalsClose(select) {
      if (select == "confirm") {
        this.modals.confirm.show = false;
        this.modals.confirm.accept = false;
      } else if (select == "alert") {
        this.modals.alert.show = false;
      }
    },
    selectedClient(cliente) {
      const lastNewEquipmentRequest = cliente.codigoestadosolicitud;
      if (lastNewEquipmentRequest) {
        if (
          lastNewEquipmentRequest === "0" ||
          lastNewEquipmentRequest === "3"
        ) {
          this.toast(
            "No puede asignar el equipo a este cliente, porque su solicitud no fue aprobada",
            "error"
          );
          return;
        }
      } else {
        this.toast("El cliente aún no hizo una solicitud", "error");
        return;
      }

      this.buscador.options = [];
      this.mode = DETAILS_MODE.EDIT;
      this.clienteMode = CLIENTE_MODE.SELECTED;
      const currentDate = new Date();
      const hoy = `${currentDate.getDate()}/${
        currentDate.getMonth() + 1
      }/${currentDate.getFullYear()}`;
      const mappedClient = {
        fecha: "fecha",
        asignado: "codigo",
        nombre: "nombre",
        segmento: "segmento",
        direccion: "direccion",
        latitud: "latitud",
        longitud: "longitud",
        minimo: "pedidoMinimo",
        comodato: "comodato",
      };
      this.buscador.cliente = editableCliente(
        { ...cliente, fecha: hoy },
        mappedClient
      );
    },
    searchClient: debounce(function (nameOrCode) {
      if (!this.general) return;
      const token = this.$store.state.auth.token;
      this.buscador.loading = true;
      const dex = this.general.dex.edit.selected; // Id de la distribuidora.
      const placa = this.general.placa.view;
      Clientes.searchByName(token, dex, nameOrCode, placa)
        .then((data) => {
          this.buscador.options = data.map((opt) => ({
            ...opt,
            text: opt.nombre,
            value: opt.codigo,
          }));
        })
        .catch(() => {
          this.toast("No se pudo listar los clientes", "error");
        });
    }, 450),
    resetBuscador() {
      this.buscador = {
        loading: false,
        name: "",
        cliente: null,
        options: [],
      };
    },
    acceptConfirm() {
      this.modals.confirm.accept = true;
      this.modals.confirm.show = false;
      this.saveData();
    },
    cancel() {
      this.mode = DETAILS_MODE.VIEW;
      // Para guardar el estado del modo edicion
      // borrar las siguientes lineas
      this.general.placa.edit = this.general.placa.view;
      this.general.lote.edit = this.general.lote.view;
      this.general.estado.edit.selected = this.general.estado.view;
      this.general.condicion.edit.selected = this.general.condicion.view;
      this.general.dex.edit.selected = this.general.dex.id ?? -1;

      if (this.general.estado?.view == "stock") {
        this.clienteMode = CLIENTE_MODE.SEARCH;
      } else {
        this.clienteMode = CLIENTE_MODE.DEFAULT;
      }
      this.resetBuscador();
    },
    handleSelectFile(entry, event) {
      const fileSelected = event.target.files[0];
      if (fileSelected && fileSelected.type == "application/pdf") {
        entry.file = fileSelected;
        entry.edit = fileSelected.name;
      }
    },
    saveData() {
      if (!this.general || !this.equipoId) return;
      const cliente = this.clienteInfo;
      const general = this.general;

      const dexSelect = general.dex.edit.selected;
      const estadoSelect = general.estado.edit.selected;

      if (
        (dexSelect > 0 && !cliente) ||
        (estadoSelect == "asignado" && dexSelect == -1)
      ) {
        return this.modalsOpen("alert");
      }

      if (!this.modals.confirm.accept) {
        //Verificar si habra un cambio en asignacion
        if (
          this.cliente &&
          this.cliente.asignado.view !== cliente?.asignado.view
        ) {
          return (this.modals.confirm.show = true);
        }
      }
      const updateData = {
        idEquipo: this.equipoId,
        placa: general.placa.edit,
        modelo: general.modelo.view,
        lote: general.lote.edit,
        estado: general.estado.edit.selected,
        condicion: general.condicion.edit.selected,
        idDistribuidora: general.dex.edit.selected,
        clienteAsignado: cliente?.asignado?.view ?? null,
      };
      // 1. Verificar la dex
      if (general.dex.edit.selected == -1) {
        updateData.idDistribuidora = null;
      }

      // 2. Verificar la condicion
      const estado = general.estado.edit.selected;
      if (estado == "baja") {
        updateData.idDistribuidora = 0;
        updateData.condicion = null;
        updateData.clienteAsignado = null;
      } else if (estado == "perdido") {
        updateData.condicion = null;
      } else if (estado == "stock") {
        updateData.idDistribuidora = 0;
        updateData.condicion = "operativo";
        updateData.clienteAsignado = cliente?.asignado?.view ?? null;
        if (updateData.clienteAsignado) {
          updateData.estado = "asignado";
        }
      } else {
        //ASIGNADO
        updateData.clienteAsignado = cliente?.asignado?.view ?? null;
        if (!updateData.clienteAsignado) {
          updateData.estado = "stock";
        }
        if (!["operativo", "mantenimiento"].includes(updateData.condicion)) {
          updateData.condicion = "operativo"; // Por defecto
        }
      }
      const token = this.$store.state.auth.token;
      this.loading = true;
      if (updateData.clienteAsignado && cliente?.comodato?.file) {
        const formData = new FormData();
        formData.append("file", cliente.comodato.file);

        Clientes.uploadComodatoFile(token, updateData.clienteAsignado, formData)
          .then((nameFile) => {
            updateData.clienteComodato = nameFile;
            Equipos.updateDetails(token, updateData)
              .then((data) => {
                if (data.status == "OK") {
                  this.toast("Equipo actualizado");
                  this.closeModal();
                  this.$emit("save");
                }
              })
              .catch(() => {
                this.toast("Error al actualizar", "error");
              })
              .finally(() => {
                this.loading = false;
              });
          })
          .catch((_e) => {
            this.toast("No se pudo cargar el comodato", "error");
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Equipos.updateDetails(token, updateData)
          .then((data) => {
            if (data.status == "OK") {
              this.toast("Equipo actualizado");
              this.closeModal();
              this.$emit("save");
            }
          })
          .catch(() => {
            this.toast("Error al actualizar", "error");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    editData() {
      this.mode = DETAILS_MODE.EDIT;
    },
    closeModal() {
      this.general = null;
      this.cliente = null;
      this.clienteMode = CLIENTE_MODE.DEFAULT;
      this.loading = false;
      this.mode = DETAILS_MODE.VIEW;
      this.resetBuscador();
      this.modalsClose("confirm");
      this.$emit("update:show", false);
    },
    changeTab() {
      if (this.equipoId == null) return;
      this.closeModal();
      this.$router.push({
        path: "/equipos-frio/maestro-activos",
        query: { tab: "incidencias", equipo: this.equipoId },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.body-modal section div.row {
  width: 100%;
}

.body-modal section:last-of-type {
  padding-top: 1em;
}

.body-modal :deep(.th-select-listbox) {
  font-size: 0.7rem;
}

.modal-buttons {
  div {
    display: flex;
  }

  div button {
    padding-inline: 1.4em;
    padding-block: 0.55em;
  }
}

.info-container {
  padding-bottom: 0.7em;
  border-bottom: 1px solid #b8b8b8;

  & :is(h3, h4) {
    color: black;
    font-weight: 600;
  }

  & h3 {
    font-size: 1rem;
  }

  & h4 {
    font-size: 0.75rem;
    margin-bottom: 0.3em;
    white-space: nowrap;
  }

  &:first-of-type span {
    text-transform: uppercase;
  }
}

.info-group {
  margin-top: 0.3em;
  display: grid;
  row-gap: 1em;
  column-gap: 1em;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(40px, 1fr));

  &:not(h4) {
    font-size: 0.67rem;
    font-weight: 300;
  }

  & :is(span, input) {
    padding-block: 0.3em;
  }

  & :deep(.editable) {
    padding-inline: 0.4em;
    border: 0;
    outline: 1px solid #e5e5e5;
    border-radius: 4px;
    width: 100%;
    min-width: 7.5em;
  }
}

.info-item-block label {
  font-weight: 400;
  font-size: 0.6rem;
}

.edit-group > span {
  text-transform: none !important;
}

.separate {
  justify-content: space-between;
}

@media screen and (min-width: 670px) {
  .info-group {
    grid-template-columns: repeat(3, minmax(30px, 1fr));
  }
}
</style>
