import { Editable, InputEditable } from "./editables";
export * from "./editables";

export class EditableHandler {
	#hasChanged = false;
	constructor() {
		this.#hasChanged = false;
		this.items = new Map();
	}
	add(key, editable) {
		if (!(editable instanceof EditableInfo))
			throw new Error("Editable must be a EditableInfo.");
		this.items.set(key, editable);
	}
	edit(key, ...params) {
		const editable = this.items.get(key);
		if (editable) {
			editable.edit(...params);
			this.#hasChanged = true;
		}
	}
	toMapped() {
		const obj = {};
		for (let [key, val] of this.items) {
			obj[key] = val.isEditable ? val.value : val.view;
		}
		return obj;
	}
	get editables() {
		// return this.items.values();
		return Array.from(this.items.values());
	}
	get hasChanged() {
		return this.#hasChanged;
	}
}

export class EditableInfo {
	constructor(label, view, isEditable, edit) {
		if (!(edit instanceof Editable))
			throw new Error("The edit field must be an instance of Editable.");
		this.label = label;
		this.view = view;
		this.isEditable = isEditable;
		this.edit = edit;
	}
	get editType() {
		return this.edit.type;
	}
	get value() {
		return this.edit.value;
	}

	static new(label) {
		return new EditableInfo(label, "", true, new InputEditable(""));
	}
	setView(view) {
		this.view = view;
		return this;
	}
	setIsEditable(bool) {
		this.isEditable = bool;
		return this;
	}
	setEditable(edit) {
		if (!(edit instanceof Editable))
			throw new Error("The edit field must be an instance of Editable.");
		this.edit = edit;
		return this;
	}
}
